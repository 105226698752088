/* eslint-disable react/jsx-max-props-per-line */
import { Box, Button, Container, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Link } from "react-router-dom";
import KjosList2 from "components/KjosList2";
import KjosSnacBar from "components/KjosSnackBar";
import { useState } from "react";
import KjosDialog from "components/KjosDialog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteResult, setOpen } from "store/AuthenticationReducer";
import useHttp from "hooks/useHttp";
import KjosInput from "components/KjosInput";
import {
  ADMIN_HABILETE_URL,
  CLASSE_SIGNALEMENT_CREATE_URL,
  CLASSE_SIGNALEMENT_GET_URL,
} from "config/RoutesUrl";
import Dialog from "components/Dialog";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import usePermission from "hooks/usePermission";
import { setAlert, setLoading, setPage, setRowPerpage } from "store/SignalementReducer";
import { useNavigate } from "react-router-dom";
import { HABILETE_EDIT_URL } from "config/PathUrl";
import KjosAlert from "components/KjosAlert";
import { CREATE_COLOR, DELETE_COLOR, FANCY_BLUE, FANCY_ORANGE, FANCY_PINK, UPDATE_COLOR } from "config/color";
import LockClosedIcon from "@heroicons/react/24/solid/LockClosedIcon";

  /* Table Header */
  const header = {
    libelle: "Libelle",
    permissions: "Permissions",
  };

const Habillete = () => {
  const dispatch = useDispatch();
  const { getData, postData, putData, deleteData } = useHttp(); // get data from server
  const { postResult, getResult, deleteResult, editResult } = useSelector(
    (state) => state.authentication
  ); // get result state
  const [motif, setMotif] = useState("add"); // add, edit
  const { itemsSelected } = useSelector((state) => state.table); // selected items from table
  const [openSnackBar, setOpenSnackBar] = useState(false); // open snackbar
    const router = useNavigate();
  const { getAllPermissions, getHabiletesPermissions } = usePermission();
  const [habiletes_permissions, setHabiletes_permissions] = useState([]);
  const { page, rowsPerPage } = useSelector((state) => state.signalement);

  /* Dialog */
  const submitCallback = async (e) => {
    dispatch(setOpen(false));
    if (libelleInputValue === "" || libelleInputValue === null || libelleInputValue === undefined) {
      dispatch(setAlert({ open: true, message: "Veuillez remplir tous les champs", color: "error" }));
      return;
    }

    if (motif === "add") {

      const data = {
        libelle: libelleInputValue,
        // type: typeInputValue,
        // url: urlInputValue, 
      };
      await postData(data, ADMIN_HABILETE_URL); //add
      dispatch(setAlert({ open: true, message: " Habilete ajoutée avec succès", color: CREATE_COLOR }));
      
    } else if (motif === "edit") {
      const data = {
        libelle: libelleInputValue,
        // url: urlInputValue,
        type: typeInputValue,
        id: itemsSelected[0],
      };
      await putData(data, ADMIN_HABILETE_URL + "/" + itemsSelected[0]); //edit
      dispatch(setAlert({ open: true, message: " Habilete modifiee avec succès", color: UPDATE_COLOR }));

    }
  };

  const [libelleInputValue, setLibelleInputValue] = useState("");
  const [typeInputValue, setTypeInputValue] = useState("");
  const [urlInputValue, setUrlInputValue] = useState("");
  const handleLibellehange = (e) => {
    setLibelleInputValue(e.target.value);
  };
  const handleTypeChange = (e) => {
    setTypeInputValue(e.target.value);
  };

  const handleUrlChange = (e) => {
    setUrlInputValue(e.target.value);
  };

  const body = (
    
      <Stack spacing={2} direction={"column"}>
        <KjosInput
          onChange={handleLibellehange}
          value={libelleInputValue}
          label="Libellé*"
        ></KjosInput>
        {/* <KjosInput onChange={handleTypeChange} value={typeInputValue} label="Type*"></KjosInput> */}
        {/* <KjosInput onChange={handleUrlChange} value={urlInputValue} label="URL"></KjosInput> */}

        {/* <Permissions /> */}
      </Stack>
    );
  
  /* End Dialog */

  /* Button control */
  const handleAdd = () => {
    setMotif("add");
    dispatch(setOpen(true));
  };

  const handlePermissions = () => {
    itemsSelected.length === 1 && router(HABILETE_EDIT_URL,{ state: { id: itemsSelected[0] } });

  }

  const handleEdit = () => {
    
    setMotif("edit");
    if (itemsSelected.length == 1) {
      getResult?.data?.forEach((item) => {
        if (item.id == itemsSelected[0]) {
          setLibelleInputValue(item?.libelle);
          setTypeInputValue(item?.type);
          // setUrlInputValue(item?.url)
        }
        return false;
      });
      dispatch(setOpen(true));
    }
  };
  const handleDelete = async () => {
    await deleteData(itemsSelected, ADMIN_HABILETE_URL); //edit
    dispatch(setAlert({ open: true, message: " Habilete supprimée avec succès", color: DELETE_COLOR }));

  };
  const handleOnclose = () => {
    setOpenSnackBar(false);
    dispatch(setDeleteResult(null));
  };
  /* En Button control */

  useEffect(() => {
    getHabiletesPermissions((page + 1), rowsPerPage).then((habiletes) => {
      console.log(habiletes);
      setHabiletes_permissions(habiletes);
      dispatch(setLoading(false)); // setLoading

    })

    if (!postResult?.error) {
      setLibelleInputValue(""); //if we submit without error reset form field
      setTypeInputValue(""); //if we submit without error reset form field
      // setUrlInputValue(""); //if we submit without error reset form field
    }

    return () => {
      setOpenSnackBar(false);
    };
  }, [getData, postResult, deleteResult, editResult, dispatch, getHabiletesPermissions, page, rowsPerPage]);

  useEffect(() => {}, [itemsSelected]);
  useEffect(() => {
    return () => {
        dispatch(setPage(0));
        dispatch(setRowPerpage(5));
        dispatch(
          setAlert({
            open: false,
            message: "",
          })
        );
    };
  }, [dispatch]);
  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
      <KjosAlert label={alert.message} />
      <Container maxWidth={"xl"}>
        <Stack spacing={3} direction={"column"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h6">Groupes</Typography>
            <div>
              <Stack direction={"row"} spacing={2}>
              
              <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <LockClosedIcon />
                    </SvgIcon>
                  }
                  disabled={itemsSelected.length == 1 ? false : true}
                  variant="contained"
                  onClick={handlePermissions}
                  sx={{ bgcolor: FANCY_PINK, color: "white" }}
                  >
                  Gestion des permissions
                </Button>


                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  // onClick={() => {router('/habiletes/create')}}
                  onClick={handleAdd}
                  sx={{ bgcolor: FANCY_BLUE, color: "white" }}
                  >
                  Ajouter
                </Button>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PencilIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="success"
                  onClick={handleEdit}
                  disabled={itemsSelected.length == 1 ? false : true}
                  sx={{ bgcolor: FANCY_PINK, color: "white" }}
                  >
                  Modifier
                </Button>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <TrashIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                  disabled={itemsSelected.length >= 1 ? false : true}
                  sx={{ bgcolor: FANCY_ORANGE, color: "white" }}
                  >
                  Supprimer
                </Button>
              </Stack>
            </div>
          </Stack>

          <Stack>
            <KjosList2 data={habiletes_permissions} header={header} isCheckBox={true} key={page} title="Les groupes" />
          </Stack>
        </Stack>
      </Container>

      <Dialog submitCallback={submitCallback} body={body} title="Ajouter" button="Valider" />

      {/* When we delete an item we open snackbar */}
      {/* <KjosSnacBar
        open={openSnackBar}
        onClose={handleOnclose}
        message={
          (deleteResult?.message?.libelle && deleteResult?.message?.libelle[0]) ??
          deleteResult?.message
        }
        autoHideDuration={6000}
        severity={
          deleteResult?.message?.libelle && deleteResult?.message?.libelle[0] ? "error" : "success"
        }
      /> */}
    </Box>
  );
};

export const Permissions = async () => {
  const { getAllPermissions } = usePermission();
  useEffect(() => {
    getAllPermissions().then((resp) => {
      console.log(resp);
    });
  }, [getAllPermissions]);
  return (
    <div>
      <h5>Autorisations:</h5>
    </div>
  );
};
export default Habillete;
