import { Backdrop, Box, Button, Chip, Container, Grid, Stack, SvgIcon, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "hooks/useHttp";
import { ADMIN_HABILETE_URL, ADMIN_SIGNALLEMENT_URL, ADMIN_TYPE_SIGNALEMENT_URL } from "config/RoutesUrl";
import KjosSttepper from "components/KjosStepper";
import useStatut from "hooks/useStatut";
import useHistorique from "hooks/useHistorique";
import getFileTypeFromUrl from "controller/getFileTypeFromUrl";
import { useRef } from "react";
import ClasseSansuiteButton from "pages/settings/ClasseSansuiteButton";
import CommentButton from "pages/settings/CommentButton";
import ReclassifyButton from "pages/settings/ReclassifyButton";
import TransfererButton from "pages/settings/TransfererButton";
import FinButton from "pages/settings/FinButton";
import useSignalement from "hooks/useSignalement";
import useUser from "hooks/useUser";
import getDefaultImage from "controller/getDefaultImage";
import ClasseSansuiteButtonDisabled from "pages/settings/ClasseSansuiteButtonDisabled";
import TransfererButtonDisabled from "pages/settings/TransfererButtonDisabled";
import useRole from "hooks/useRole";
import { setAlert } from "store/SignalementReducer";
import KjosAlert from "components/KjosAlert";
import KjosChip from "components/KjosChip";
import ArrowUpTrayIcon from "@heroicons/react/24/solid/ArrowUpTrayIcon";
import { CREATE_COLOR, FANCY_GREEN } from "config/color";
import formatUserName from "controller/formatUserName";
import { RAPPORT_SEND, RAPPORT_SEND_BY_FILTER } from "config/PathUrl";
import KjosMap from "components/KjosMap";

const SignalementDetailLayout = () => {
  const { getOneData, getData } = useHttp(); // get data from server
  const router = useLocation();
  const { oneDataResult, update } = useSelector((state) => state.authentication); // get result state
  const [openSnackBar, setOpenSnackBar] = useState(false); // open snackbar
  const [signalement, setSignalement] = useState([]); // open snackbar
  const [typeSignalement, setTypeSignalement] = useState([]);
  const [habiletes, setHabiletes] = useState([]);
  const { color, label } = useStatut();
  const { getHistorique, setHistorique } = useHistorique();
  // const [historique, setHistorique] = useState([]);
  const gridDetailsRef = useRef();
  const { updateSignalement, isSignalementOwner, endedSignalement, nexGroup, sendRepport } = useSignalement();
  const { getUser, getUserHabilitation } = useUser();
  const [isOwner, setIsOwner] = useState(true);
  const [finTraitement, setFinTraitement] = useState(false);
  const isSuperAdmin = useRole("admin", "superadmin");
  const { alert } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [position, setPosition] = useState(null);
  const handleReport = async () => {
    navigate(RAPPORT_SEND_BY_FILTER, { state: { signalement_id: router?.state?.id, signalement_code: signalement?.code, filter: "by_signalement_id", nextPage: true } });
    // const nextG = await sendRepport(router?.state?.id);
    // console.log("nextG", nextG);

    // dispatch(setAlert({ open: true, message: "Le rapport a été envoyé par mail au groupe avec succès", color: CREATE_COLOR }));
  };

  useEffect(() => {
    // Marque signalement as open
    async function fetchData() {
      console.log("router?.state?.id");
      console.log(router?.state?.id);
      const sign = await getOneData(router?.state?.id, ADMIN_SIGNALLEMENT_URL);
      console.log("sign", sign);
      const typeSignAll = await getData(ADMIN_TYPE_SIGNALEMENT_URL);
      const habil = (await getData(ADMIN_HABILETE_URL))?.data;
      
      // Check if is owner
      const own = await isSignalementOwner(router?.state?.id);
      setIsOwner(own);
      // On marque le signalement open s'il ne l'est pas
      if (sign?.data?.statut === "new") {
        updateSignalement(router?.state?.id, { statut: "open" })?.then((res) => {});

        setHistorique("open", sign?.data?.id, getUser("admin")?.user?.id, "L'administrateur " + getUser("admin")?.user?.nom + " a ouvert le signalement: ")?.then((res) => {});
      }

      // fin traitement
      const fin = endedSignalement(sign?.data);
      setFinTraitement(fin);

      console.log("dataaaaaaaaaaaaa");
      console.log(sign?.data);

      setSignalement(sign?.data);

      setTypeSignalement(typeSignAll?.data);
      setHabiletes(habil);

      // const hist = await getHistorique(router?.state?.id);
      // !hist?.error && setHistorique(hist?.data);
    }
    fetchData(); // get data when component is mounted

    oneDataResult && setOpenSnackBar(true); //if we delete an item we open snackbar
    return () => {
      setOpenSnackBar(false);
    };
  }, [router?.state?.id, update]);

  useEffect(() => {
    if (gridDetailsRef.current) {
      const divHeight = gridDetailsRef.current.clientHeight;
      console.log("Div height:", divHeight);
    }
    return () => {
      dispatch(
        setAlert({
          open: false,
          message: "",
          color: "",
        })
      );
    };
  }, []);

  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem", backgroundColor: "#F6F7FA", borderRadius: "10px" }}>
      <KjosAlert label={alert.message} />
      <Container maxWidth={"xl"} sx={{ backgroundColor: "white", p: 5, borderRadius: 2, boxShadow: "0px 0px 5px grey" }}>
        <Stack></Stack>

        <Grid container spacing={2}>
          <Grid item md={12} lg={12}>
            <div>
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                {isOwner ? (
                  <>
                    <CommentButton signalement={signalement} signalementId={router?.state?.id} owner={isOwner} />

                    <TransfererButton signalement={signalement} signalementId={router?.state?.id} owner={isOwner} />

                    <ClasseSansuiteButton signalement={signalement} signalementId={router?.state?.id} owner={isOwner} />
                    <ReclassifyButton signalement={signalement} signalementId={router?.state?.id} owner={isOwner} />
                  </>
                ) : (
                  <>
                    <TransfererButtonDisabled />
                    <TransfererButtonDisabled />
                    <ClasseSansuiteButtonDisabled />
                  </>
                )}

                {signalement?.statut === "delivery" && isOwner && (
                  <Button
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpTrayIcon />
                      </SvgIcon>
                    }
                    variant="contained"
                    color="success"
                    onClick={handleReport}
                    sx={{ bgcolor: FANCY_GREEN, color: "white" }}
                  >
                    Générer le rapport
                  </Button>
                )}

                {signalement?.statut !== "delivery" && !isOwner && (
                  // <Backdrop open={true} disableportal={true}>
                  //   <Typography variant="h4" color={"error"}>
                  //     SIGNALEMENT TRANSFERÉ
                  //   </Typography>
                  // </Backdrop>
                  <KjosChip color={"error"} label={"Transféré"} variant={"filled"} />
                )}
              </Stack>
            </div>
          </Grid>
          <Grid item md={12} lg={8} sx={{ p: 4 }} ref={gridDetailsRef}>
            <Stack
              spacing={2}
              sx={{
                p: 4,
                backgroundColor: "#F6F7FA",
                borderRadius: "10px",
                boxShadow: "0px 0px 5px grey",
              }}
              direction={"column"}
              justifyContent={"space-between"}
            >
              <Typography variant="h6">Détails</Typography>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Numéro de signalement</Typography>
                <Typography variant="body">{signalement?.code}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Type de signalement</Typography>
                <Typography variant="body">{signalement?.type_signalement?.libelle}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Groupe du signalement</Typography>
                <Typography variant="body">{signalement?.classe?.libelle}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Localité</Typography>
                <Typography variant="body">{signalement?.commune?.libelle}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Entité mise en cause</Typography>
                <Typography variant="body">{signalement?.source?.libelle}</Typography>
              </Stack>

              {
                signalement?.source_des_donnees && signalement?.source_des_donnees?.libelle && 
                <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Source des données</Typography>
                <Typography variant="body">{signalement?.source_des_donnees?.libelle}</Typography>
              </Stack>
              }
              {/* 
              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Elément en cause</Typography>
                <Typography variant="body">{signalement?.element_en_cause}</Typography>
              </Stack> */}

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Utilisateur</Typography>
                <Typography variant="body">{formatUserName(signalement)}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Date du signalement</Typography>
                <Typography variant="body">{signalement?.date}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Status</Typography>
                <Typography variant="body">
                  <KjosChip label={label(signalement?.statut)} color={color(signalement?.statut)} size="small" variant="contained" />
                </Typography>
              </Stack>

              <Stack spacing={2} direction={"column"}>
                <Typography variant="body">Description</Typography>
                <Stack
                  sx={{
                    p: 4,
                    height: "10rem",
                    overflow: "auto",
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="body">{signalement?.description}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={12} lg={4} sx={{ p: 4, height: gridDetailsRef.current?.clientHeight, overflow: "auto" }}>
            <Stack spacing={2}>
              <Stack
                spacing={2}
                direction={"column"}
                sx={{
                  p: 4,
                  backgroundColor: "#F6F7FA",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 5px grey",
                }}
              >
                <Typography variant="h6">historique</Typography>
                <KjosSttepper steps={signalement?.historiques ?? []} />
              </Stack>
            </Stack>
          </Grid>

          <Grid item md={12} lg={12}>
            <Typography variant="h6">Pièces jointes:</Typography>
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{
                backgroundColor: "white",
                p: 4,
                mt: 1,
                borderRadius: 2,
                boxShadow: "0px 0px 5px grey",
                overflow: "auto",
              }}
            >
              <Stack direction={"column"} spacing={2}>
                {signalement?.medias?.map((preuv, index) => {
                  return (
                    <>
                      {(preuv?.type_media === "images" || preuv?.type_media === "documents") && (
                        <Link to={`${getDefaultImage(preuv?.full_path_image)}`} key={index} target="_blank">
                          <Typography variant="body" key={index + "image"}>
                            {index + 1} - Pièce jointe {index + 1}
                          </Typography>
                        </Link>
                      )}

                      {preuv?.type_media === "audio" && (
                        <audio controls key={index + "audio"}>
                          <source src={`${getDefaultImage(preuv?.full_path_image)}`} type="audio/ogg" />
                          {/* <source src="horse.mp3" type="audio/mpeg" /> */}
                          Your browser does not support the audio element.
                        </audio>
                      )}

                      {preuv?.type_media === "video" && (
                        <Link to={`${getDefaultImage(preuv?.full_path_image)}`} key={index + "video"} target="_blank">
                          <Typography variant="body" key={index}>
                            {index + 1} - Pièce jointe {index + 1}
                          </Typography>
                        </Link>
                      )}

                      {preuv?.type_media === null && (
                        <Link to={`${getDefaultImage(preuv?.full_path_image)}`} key={index + "unknown"} target="_blank">
                          <Typography variant="body" key={index}>
                            {index + 1} - Pièce jointe {index + 1}
                          </Typography>
                        </Link>
                      )}
                    </>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>

          {/* Map */}
          <Grid item md={12} lg={12}>
            <Typography variant="h6">Cartographie:</Typography> 
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{
                backgroundColor: "white",
                p: 4,
                mt: 1,
                borderRadius: 2,
                boxShadow: "0px 0px 5px grey",
                overflow: "auto",
              }}
            >
              <Stack direction={"column"} spacing={2}>
                <KjosMap locations={[signalement]} zoom={15} position={position} />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SignalementDetailLayout;
