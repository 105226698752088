import { useCallback, useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Stack, TextField, Typography } from "@mui/material";
import useUser from "hooks/useUser";
import KjosInput from "components/KjosInput";
import useHttp from "hooks/useHttp";
import { INIT_PASSWORD_URL } from "config/RoutesUrl";
import { set } from "nprogress";
import KjosSnacBar from "components/KjosSnackBar";
import KjosInput2 from "components/KjosInput2";
import { FANCY_GREEN, MAIN_GRAY, UPDATE_COLOR } from "config/color";
import { useDispatch } from "react-redux";
import { setAlert } from "store/SignalementReducer";
import scrollToTop from "controller/scrollToTop";

export const SettingsPassword = () => {
  const [values, setValues] = useState({
    password: "",
    confirm: "",
  });
  const { getData, postData, putData, deleteData } = useHttp(); // get data from server
  const { getUser } = useUser();
  const [updatePassword, setUpdatePassword] = useState([]);
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 5000,
  });
  const handleOncloseSanackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget); // get form data
      const resp = await postData(
        {
          password: formData.get("password"),
          password_confirmation: formData.get("password_confirmation"),
          role: getUser("admin")?.user?.role,
          email: getUser("admin")?.user?.email,
        },
        INIT_PASSWORD_URL
      );
      setUpdatePassword(resp);
      // Snackbar
      !resp?.error &&
        // setSnackbar({
        //   open: true,
        //   message: "Le mot de passe a été mis à jour",
        //   severity: "error",
        //   autoHideDuration: 5000,
        // });
        dispatch(setAlert({ open: true, message: "Le mot de passe a été mis à jour", color: UPDATE_COLOR }));
      setUpdatePassword("");
      scrollToTop();
    },
    [getUser, postData]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Card sx={{ bgcolor: MAIN_GRAY }}>
        <CardHeader subheader="Mise à jour" title="Mot de passe"  sx={{backgroundColor: FANCY_GREEN, color: "white"}}/>
        <Divider />
        <CardContent>
          <Stack spacing={3} sx={{ maxWidth: 400 }}>
            <KjosInput2 label="Ancien mot de passe" id="password" name="password" type="password" />

            <KjosInput2 id="password_confirmation" label="Nouveau mot de passe" name="password_confirmation" type="password" />
          </Stack>
          <Stack>
            {updatePassword?.error && (
              <Typography color="error" variant="body2">
                {Object.keys(updatePassword?.message)?.map((key, index) => updatePassword?.message[key]?.map((item, index) => <li key={index}>{item}</li>))}
              </Typography>
            )}
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button type="submit" variant="contained" sx={{ bgcolor: FANCY_GREEN, color: "white" }}>
            Valider
          </Button>
        </CardActions>
      </Card>

      {/* <KjosSnacBar
        open={snackbar?.open}
        message={snackbar?.message}
        severiry={snackbar?.severity}
        onClose={handleOncloseSanackbar}
        autoHideDuration={snackbar?.autoHideDuration}
      /> */}
    </form>
  );
};
