import { useCallback } from "react";
import useHistorique from "./useHistorique";
import useSignalement from "./useSignalement";
import {
  ADMIN_COMMENTAIRE_URL,
  ADMIN_SIGNALLEMENT_URL,
  ANONYME_HISTORIQUE_URL,
} from "config/RoutesUrl";
import useCommentaire from "./useCommentaire";
import { useDispatch } from "react-redux";
import { setUpdate } from "store/AuthenticationReducer";
import useHttp from "./useHttp";
import useRole from "./useRole";
import usePermission from "./usePermission";

export default function useActionButton() {
  const { getHistorique } = useHistorique();
  const { nexGroup, isSignalementOwner } = useSignalement();
  const { getCommentaire } = useCommentaire();
  const dispatch = useDispatch();
  const { getOneData } = useHttp();
  const isSuperAdmin = useRole("admin", "superadmin");
  const { hasPermission } = usePermission();

  // Le bouton comment est désactivé dans ces cas suivants: le signalement est sans suite, le n'appartient pas au membre connecté, le signalement appartient à l'utilisateur connecté et est marqué comme terminé
  const disableCommentButton = useCallback(
    async (signalementId) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;

      let disabled = false;

      if (signalement?.statut === "without_continuation" || signalement?.statut === "delivery") {
        return true;
      }
      if(isSuperAdmin){
        return false
      }else{
        const owner = await isSignalementOwner(signalement?.id);
        if (owner) {
          return false;
        } else {
          return true;
        }
      }
      
      return disabled;
    },
    [getOneData, isSignalementOwner, isSuperAdmin]
  );

  /*  Le bouton tranfert est désactivé si le:
   - signalement est sans suite, 
   - signalement n'a pas de commentaire, 
   - signalament n'appartient plus à l'utilisateur connecté 
   - signalement est terminé 
   */
  const disableTransfertButton = useCallback(
    async (signalementId) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;

      var disabled = false;

      if (signalement?.statut === "without_continuation" || signalement?.statut === "delivery") {
        return true;
      }
      const comment = await getCommentaire(signalementId);
      if (Array.isArray(comment?.data) && comment?.data?.length === 0) {
        return true;
      }

      if(isSuperAdmin){
        return false
      }else{
        const owner = await isSignalementOwner(signalementId);
        if (!owner) {
          return true;
        }
      }
    

      return false;
    },
    [getCommentaire, getOneData, isSignalementOwner, isSuperAdmin]
  );

  /*  Le bouton classer sans suite est désactivé si le:
   - signalement est sans suite, 
   - signalement n'a pas de commentaire, 
   - signalament n'appartient plus à l'utilisateur connecté 
   - signalement est terminé 
   */
  const disableClasseSansSuiteButton = useCallback(
    async (signalementId) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;

      if (signalement?.statut === "without_continuation" || signalement?.statut === "delivery") {
        return true;
      }
      const comment = await getCommentaire(signalementId);
      if (Array.isArray(comment?.data) && comment?.data?.length === 0) {
        return true;
      }

      if(isSuperAdmin){
        return false
      }else{
        const owner = await isSignalementOwner(signalementId);
        if (!owner) {
          return true;
        }
      }
    

      return false;
    },
    [getCommentaire, getOneData, isSignalementOwner, isSuperAdmin]
  );

  const disableReclassifyButton = useCallback(
    async (signalementId) => {

      // Check permission
      const authorized = (await hasPermission("reclassify_signalement"))?.authorized;

      if(!authorized){
        return true
      }

      const comment = await getCommentaire(signalementId);
      if (Array.isArray(comment?.data) && comment?.data?.length === 0) {
        return true;
      }

      if(isSuperAdmin){
        return false
      }else{
        const owner = await isSignalementOwner(signalementId);
        if (!owner) {
          return true;
        }
      }
    

      return false;
    },
    [getCommentaire, getOneData, isSignalementOwner, isSuperAdmin]
  );


  return {
    disableCommentButton,
    disableTransfertButton,
    disableClasseSansSuiteButton,
    disableReclassifyButton,
  };
}
