import { useCallback } from "react"
import {ADMIN_COMMUNE_URL} from "../config/RoutesUrl"
import useHttp from "../hooks/useHttp"
export default function useCommune() {
   const {getData} = useHttp();
   const getAllCommune = useCallback(async () => {

      const data = (await getData(ADMIN_COMMUNE_URL)).data
      const commune = data?.map((commune) => (
         {
            id: commune?.id,
            label: commune?.libelle
         }
      ))
      console.log(commune)
      return commune
   })

   return {
      getAllCommune
   }
}