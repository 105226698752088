import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import { Avatar, Badge, Box, IconButton, Stack, SvgIcon, Tooltip, useMediaQuery } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { usePopover } from "hooks/use-popover";
import AccountPopover from "./account-popover";
import NotificationsPopover from "./notifications-popover";
import { useDispatch, useSelector } from "react-redux";
import { avatar_anonyme } from "config/image";
import getDefaultImage from "controller/getDefaultImage";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import BellIcon from "@heroicons/react/24/solid/BellIcon";
import { setCountNotification } from "store/SignalementReducer";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

const TopNav = (props) => {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const accountPopover = usePopover();
  const notificationsPopover = usePopover();
  const { user } = useSelector((state) => state.authentication);
  const { countNotification } = useSelector((state) => state.signalement);
  const dispatch = useDispatch();
  return ( 
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          left: {
            lg: `${SIDE_NAV_WIDTH}px`,
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
          },
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
            {/* <Tooltip title="Search">
              <IconButton>
                <SvgIcon fontSize="small">
                  <MagnifyingGlassIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip> */}
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            {/*  <Tooltip title="Contacts">
              <IconButton>
                <SvgIcon fontSize="small">
                  <UsersIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Notifications">
              <IconButton onClick={notificationsPopover.handleOpen} 
                ref={notificationsPopover.anchorRef}>
                <Badge badgeContent={countNotification > 99 ? "99+" : countNotification} color="error">
                  <SvgIcon fontSize="small">
                    <BellIcon />
                  </SvgIcon>
                </Badge>
              </IconButton>
            </Tooltip>
            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                cursor: "pointer",
                height: 40,
                width: 40,
              }}
              src={getDefaultImage(user?.photo) ?? avatar_anonyme}
            />
          </Stack>
        </Stack>
      </Box>
      <AccountPopover anchorEl={accountPopover.anchorRef.current} open={accountPopover.open} onClose={accountPopover.handleClose} />

      <NotificationsPopover anchorEl={notificationsPopover.anchorRef.current} open={notificationsPopover.open} onClose={notificationsPopover.handleClose} />
    </>
  );
};

export default TopNav;
