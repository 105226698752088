import { useCallback, useState } from "react";
import useHttp from "hooks/useHttp";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_USER_URL, ADMIN_HABILETE_URL, ADMIN_COMMUNE_URL } from "config/RoutesUrl";

import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, TextField, Unstable_Grid2 as Grid, MenuItem, Typography, Stack, Chip } from "@mui/material";
import useUser from "hooks/useUser";
import useRegister from "hooks/useRegister";
import KjosInput2 from "components/KjosInput2";
import KjosSnacBar from "components/KjosSnackBar";
import KjosInputImage from "components/KjosInputImage";
import { avatar_anonyme } from "config/image";
import { useRef } from "react";
import { CREATE_COLOR, FANCY_GREEN, MAIN_BLUE, MAIN_GRAY } from "config/color";
import KjosSelect4 from "components/KjosSelect4";
import { useEffect } from "react";
import { TroubleshootRounded } from "@mui/icons-material";
import axios from "axios";
import { BASE_URL, BASE_URL_API, CSRF_COOKIES, REGISTER_URL } from "config/RoutesUrl";
import formatPhoneNumber from "controller/formatPhoneNumber";
import { setAlert } from "store/SignalementReducer";
import { useNavigate } from "react-router-dom";
import scrollToTop from "controller/scrollToTop";
import KjosAlert from "components/KjosAlert";

const AdminAdd = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const { handleFormSubmit } = useRegister();
  const photoCleanedRef = useRef(false);
  const formRef = useRef();
  const photoRef = useRef();
  const { getData } = useHttp(); // get data from server
  const { alert } = useSelector((state) => state.signalement);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 5000,
  });
  const [postUser, setPostUser] = useState([]);

  const handleLocalFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget); // get form data
    const formData2 = new FormData();

    let contact = formData.get("contact");
    contact = contact.replace(/[^\d]/g, "");
    formData2.append("nom", formData.get("nom"));
    formData2.append("prenom", formData.get("prenom"));
    formData2.append("contact", contact);
    formData2.append("email", formData.get("email"));
    formData2.append("ville", formData.get("ville"));
    formData2.append("role", "admin");
    formData2.append("pays", formData.get("pays"));
    formData2.append("habilete_id", formData.get("habilete_id"));
    formData2.append("date_naissance", formData.get("date_naissance"));
    formData2.append("genre", formData.get("genre"));
    // formData2.append("password", formData.get("password"));
    // formData2.append("password_confirmation", formData.get("password_confirmation"));
    formData2.append("commune_id", formData.get("commune_id"));

    // Si on change de photo
    if (!photoCleanedRef.current && photoRef.current.files[0]) {
      formData2.append("photo", photoRef.current.files[0]);
      formData2.append("photo_changed", "changed");
    }

    // Si on supprime photo
    if (photoCleanedRef.current && !photoRef.current.files[0]) {
      formData2.append("photo", "cleaned");
      formData2.append("photo_changed", "changed");
    }

    // Si photo ne change pas et on supprime pas on garde l'ancienne photo
    if (!photoCleanedRef.current && !photoRef.current.files[0]) {
      formData2.append("photo_changed", "unchanged");
    }

    try {
      // await axios.get(tokenUrl); // ping the route
      const request = await axios.post(BASE_URL_API + REGISTER_URL, formData2); // get user
      const response = request;
      console.log(response);
      if (response?.data?.error || response?.error) {
        // setSnackbar({
        //   open: true,
        //   message: response?.data?.message || response?.message,
        //   severity: "error",
        //   autoHideDuration: 5000,
        // });

        dispatch(setAlert({ open: true, message: response?.data?.message || response?.message, color: "error" }));
        scrollToTop();
      } else {
        photoRef.current.value = "";
        formRef.current.reset();
        dispatch(
          setAlert({
            open: true,
            message: "Utilisateur enregistré avec succès",
            color: CREATE_COLOR,
          })
        );
        router(-1);
        // setSnackbar({
        //   open: true,
        //   message: "Utilisateur enregistré avec succès",
        //   severity: "success",
        //   autoHideDuration: 5000,
        // });
      }
    } catch (error) {
      console.log("erreur interne ", error);
    }
  };

  const handleOncloseSanackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };
  const [habilletes, setHabilletes] = useState([]);
  const [gender, setGender] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [communes, setCommunes] = useState([]);

  const handleRadioChange = (event) => {
    setGender(event.target.value);
  };

  useEffect(() => {
    async function fetchData() {
      const hab = (await getData(ADMIN_HABILETE_URL))?.data?.map((item) => ({
        label: item.libelle,
        value: item.id,
        id: item.id,
      }));
      setHabilletes(hab);

      const com = (await getData(ADMIN_COMMUNE_URL))?.data?.map((item) => ({
        label: item.libelle,
        value: item.id,
        id: item.id,
      }));
      setCommunes(com);
    }

    fetchData();
  }, []);

  return (
    <form onSubmit={handleLocalFormSubmit} ref={formRef}>
      <KjosAlert label={alert.message} />
      <Grid container spacing={3}>
        <Grid xs={12} md={6} lg={4}>
          <Card sx={{ bgcolor: MAIN_GRAY, height: "100%" }}>
            <CardHeader subheader="Veuillez choisir une pohto" title={"Photo"} sx={{ backgroundColor: FANCY_GREEN, color: "white" }} />
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <KjosInputImage
                  photoRef={photoRef}
                  // source={getDefaultImage(user?.photo)}
                  defaultImage={avatar_anonyme}
                  photoCleanedRef={photoCleanedRef}
                />

                {/* <Typography
        gutterBottom
        variant="h5"
      >
        {user.name}
      </Typography> */}
                {/* <Typography
        color="text.secondary"
        variant="body2"
      >
        {user.city} {user.country}
      </Typography> */}
                {/* <Typography
        color="text.secondary"
        variant="body2"
      >
        {user.timezone}
      </Typography> */}
                <Typography mt={2} variant="body2">
                  10Mo max (jpg, png)
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={12} md={6} lg={8}>
          <Card sx={{ bgcolor: MAIN_GRAY }}>
            <CardHeader subheader="Veuillez renseigner tous les champs" title={<div>Creation du compte </div>} sx={{ backgroundColor: FANCY_GREEN, color: "white" }} />
            <CardContent sx={{ pt: 0 }}>
              <Box sx={{ m: -1.5 }}>
                <Grid container spacing={3}>
                  <Grid xs={12} md={6}>
                    <KjosInput2 type="text" name="nom" id="nom" label="Nom" required width={"100%"} />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <KjosInput2 type="text" name="prenom" id="prenom" label="Prénom" required width={"100%"} />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <KjosInput2 type="email" label="Adresse Email" name="email" id="email" required width={"100%"} autoComplete="new-password" />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <KjosInput2
                      id="contact"
                      label="Numéro de téléphone"
                      name="contact"
                      type="text"
                      required
                      width={"100%"}
                      value={phoneNumber}
                      autoComplete="new-password"
                      onChange={(event) => {
                        setPhoneNumber(formatPhoneNumber(event));
                      }}
                    />
                  </Grid>
                  {/* 
                  <Grid xs={12} md={6}>
                    <KjosInput2
                      type="password"
                      id="password"
                      label="Mot de passe"
                      name="password"
                      width={"100%"}
                      autoComplete="new-password"
                      required
                    />
                  </Grid>
                    <Grid xs={12} md={6}>
                    <KjosInput2
                      type="password"
                      id="password_confirmation"
                      label="Confirmer le mot de passe"
                      name="password_confirmation"
                      autoComplete="new-password"
                      width={"100%"}
                    />
                  </Grid> */}

                  <Grid xs={12} md={6}>
                    <KjosInput2 type="text" id="pays" label="Pays" name="pays" width={"100%"} />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <KjosInput2 type="text" id="ville" label="Ville" name="ville" width={"100%"} />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <KjosSelect4 id="commune_id" label="Localité" name="commune_id" width={"100%"} options={communes} />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <KjosInput2 type="date" id="date_naissance" label="Date de naissance" name="date_naissance" width={"100%"} />
                  </Grid>

                  <Grid xs={12} md={6} alignItems={"center"}>
                    <Typography variant="body2">Genre</Typography>
                    <Stack direction="row" spacing={8}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="body2">Masculin</Typography>
                        <input type="radio" id="homme" name="genre" value={"homme"} checked={gender == "homme" ? true : false} onChange={handleRadioChange} />
                      </Stack>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="body2">Feminin</Typography>
                        <input type="radio" id="femme" name="genre" value={"femme"} checked={gender == "femme" ? true : false} onChange={handleRadioChange} />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <KjosSelect4 id="habilete_id" label="Habiletation " name="habilete_id" width={"100%"} options={habilletes} />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained" sx={{ bgcolor: FANCY_GREEN }}>
                Enregistrer
              </Button>
            </CardActions>
          </Card>

          {postUser?.error && (
            <Typography color="error" sx={{ mt: 3 }} variant="body2">
              {postUser?.message}
            </Typography>
          )}

          <KjosSnacBar open={snackbar?.open} message={snackbar?.message} severiry={snackbar?.severity} onClose={handleOncloseSanackbar} autoHideDuration={snackbar?.autoHideDuration} />
        </Grid>
      </Grid>
    </form>
  );
};
export default AdminAdd;
