import { useState } from "react";
import useHttp from "hooks/useHttp";
import { useDispatch, useSelector } from "react-redux";
import DescriptionIcon from "@mui/icons-material/Description";

import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Unstable_Grid2 as Grid, MenuItem, Typography, Stack, Chip } from "@mui/material";

import useUser from "hooks/useUser";
import KjosInput2 from "components/KjosInput2";
import KjosSnacBar from "components/KjosSnackBar";
import { useRef } from "react";
import { CREATE_COLOR, DELETE_COLOR, FANCY_GREEN, MAIN_BLUE, MAIN_GRAY, UPDATE_COLOR } from "config/color";
import getFileToTransfert from "controller/getFileToTransfert";
import getDefaultImage from "controller/getDefaultImage";
import KjosSelect4 from "components/KjosSelect4";
import { useEffect } from "react";

import KjosHeader from "components/KjosHeader";
import { Container } from "@mui/material";
import KjosInputFile from "components/KjosInputFile";
import { ADMIN_SIGNALLEMENT_URL, ADMIN_TYPE_SIGNALEMENT_URL, ADMIN_ADMIN_ACTION_URL, SOURCE_DE_DONNEE_URL, ADMIN_ARTICLE_URL } from "config/RoutesUrl";
import useSignalement from "hooks/useSignalement";
import useHistorique from "hooks/useHistorique";
import KjosAuthorized from "components/KjosAuthorized";
import KjosTextArea from "components/KjosTextArea";
import { avatar_anonyme, image_anonyme, upload_file_icon } from "config/image";

import { styled } from "@mui/material/styles";
import { setAlert } from "store/SignalementReducer";
import { useLocation, useNavigate } from "react-router-dom";
import KjosTitle from "components/KjosTitle";
import BellAlertIcon from "@heroicons/react/24/solid/BellAlertIcon";
import useRole from "hooks/useRole";
import KjosRichText2 from "components/KjosRichText2";
import KjosInputImage from "components/KjosInputImage";
import KjosAlert from "components/KjosAlert";
import scrollToTop from "controller/scrollToTop";

const Image = styled("img")({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
});

const EditArticle = (props) => {
  const dispatch = useDispatch();
  const {  getOneData, putData } = useHttp(); // get data from server
  const { createSignalement } = useSignalement(); // get data from server
  const router = useNavigate();
  const { setHistorique } = useHistorique();
  const [typeSignalement, setTypeSignalement] = useState([]);
  const [sourceSignalement, setSourceSignalement] = useState([]);
  const [signalement, setSignalement] = useState([]);
  const photoRef = useRef();
  const photoCleanedRef = useRef(false);
  const fileRef = useRef();
  const audioRef = useRef();
  const videoRef = useRef();
  const { alert } = useSelector((state) => state.signalement);

  const formRef = useRef();
  const fileListRef = useRef();
  const errorFileListRef = useRef();
  const refImage = useRef();
  const mainImageRef = useRef();
  const isSuperAdmin = useRole("admin", "superadmin");

  const [contenu, setContenu] = useState("");
  const handleOnRichTextUpdate = (value) => {
    setContenu(value);
  };

  const location = useLocation();
  const article_id = location.state?.article_id;
  const [article, setArticle] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getOneData(article_id, ADMIN_ARTICLE_URL).then((data) => {
      console.log(data);
      setContenu(data?.data?.contenu);
      setArticle(data.data);
    });
  }, [getOneData, article_id]);


  <KjosHeader>Créer un article | ANSI</KjosHeader>;

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if ((contenu === "" && article?.contenu === "") || (contenu === "<p><br></p>" && article?.contenu === "<p><br></p>") || !contenu && !article?.contenu || !contenu.trim() && !article?.contenu.trim() || contenu.trim() === "<p></p>" && article?.contenu.trim() === "<p></p>" || contenu.trim() === "" && article?.contenu.trim() === "") {
      dispatch(setAlert({ open: true, message: "Le contenu ne doit pas être vide", color: DELETE_COLOR }));
      scrollToTop();
      return;
    }

    const formData = new FormData(e.currentTarget);
    const formData2 = new FormData();
    console.log("contenu", contenu);
    formData2.append("contenu", contenu ?? article?.contenu);
    formData2.append("titre", formData.get("titre"));

    console.log(formData.get("titre"));
    // Si on change de photo
    if (!photoCleanedRef.current && photoRef.current.files[0]) {
      formData2.append("image", photoRef.current.files[0]);
      formData2.append("image_changed", "changed");
    }

    // Si on supprime photo
    if (photoCleanedRef.current && !photoRef.current.files[0]) {
      formData2.append("image", "cleaned");
      formData2.append("image_changed", "changed");
    }

    // Si photo ne change pas et on supprime pas on garde l'ancienne photo
    if (!photoCleanedRef.current && !photoRef.current.files[0]) {
      formData2.append("image_changed", "unchanged");
    }

    const creatArt = await putData(formData2, ADMIN_ARTICLE_URL + `/${location.state?.article_id}`);
    if (creatArt?.error || !creatArt || creatArt === undefined) {
      dispatch(setAlert({ open: true, message: creatArt?.message, color: DELETE_COLOR }));
      scrollToTop();
      return;

    } else {
      formRef.current.reset();

      dispatch(
        setAlert({
          open: true,
          message: "L'article a été mise à jour avec succès",
          color: UPDATE_COLOR,
        })
      );
      router(-1);
      // snackbar?.data
      // setSnackbar({
      //   open: true,
      //   message: creatSign?.message,
      //   severity: "success",
      //   autoHideDuration: 5000,
      // });
    }
  }

  return (
    <KjosAuthorized permission="articles_management">
      <KjosHeader>Créer un signalement | ANLC</KjosHeader>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <KjosTitle icon={<DescriptionIcon />} title="Article" subtitle="Ajout d'un nouveau article" iconBgColor={FANCY_GREEN} />
          <br />

          <form onSubmit={handleFormSubmit} ref={formRef}>
            <KjosAlert label={alert.message} />
            <Grid container spacing={3}>
              <Grid xs={12} md={6} lg={4}>
                <Card sx={{ bgcolor: MAIN_GRAY, height: "100%" }}>
                  <CardHeader subheader="Veuillez sélectionner une image à ajouter" title={<div>Image </div>} sx={{ backgroundColor: FANCY_GREEN, color: "white" }} />
                  <CardContent>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {/* <KjosRadioButton label={label1} label_desc={label_desc1} width="100%" /> */}

                      <KjosInputImage
                        photoRef={photoRef}
                        // source={getDefaultImage(user?.photo)}
                        defaultImage={image_anonyme}
                        photoCleanedRef={photoCleanedRef}
                        source={getDefaultImage(article?.image)}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid xs={12} md={6} lg={8}>
                <Card sx={{ bgcolor: MAIN_GRAY }}>
                  <CardHeader subheader="Renseignez les informations de l'article" title={<div>Article </div>} sx={{ backgroundColor: FANCY_GREEN, color: "white" }} />
                  <CardContent sx={{ pt: 0, height: "25rem", overflow: "auto" }}>
                    <br />
                    <KjosInput2 type="text" name="titre" id="titre" label="Titre" required width={"100%"} defaultValue={article?.titre} />
                    <br />
                    <KjosRichText2 onUpdate={handleOnRichTextUpdate} defaultContent={article?.contenu} />
                  </CardContent>
                  <Divider />
                  <CardActions sx={{ justifyContent: "flex-end" }}>
                    <Button type="submit" variant="contained" sx={{ bgcolor: FANCY_GREEN }}>
                      Enregistrer
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Box>
    </KjosAuthorized>
  );
};

export default EditArticle;
