export default function getFileTypeFromUrl(url) {
  const fileExtension = url.split('.').pop().toLowerCase();
  if (fileExtension.startsWith("mp3") || fileExtension.startsWith("wav") || fileExtension.startsWith("ogg")) {
    return "audio";
  } else if (fileExtension.startsWith("mp4") || fileExtension.startsWith("avi") || fileExtension.startsWith("mov")) {
    return "video";
  } else if (fileExtension.startsWith("jpg") || fileExtension.startsWith("jpeg") || fileExtension.startsWith("png")) {
    return "image";
  } else {
    return "unknown";
  }
}