import { Box } from "@mui/system";
import KjosHeader from "components/KjosHeader";
export default function UnAuthorize() {
  return (
    <Box display="flex" flexDirection={"column"}  alignItems="center">
      <KjosHeader>
        Tableau de bord | ANLC
      </KjosHeader>
      {/* <h1>Accès réfusé:</h1>
      <p>You do not have permission to view this page.</p>
      <p>Please contact your administrator.</p> */}
    </Box>
  );
}
