import React from 'react';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ReviewComponent = ({ value, max }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Rating name="read-only" value={value} max={max} readOnly />
      {/* <Typography component="legend">{value} out of {max}</Typography> */}
    </Box>
  );
};

export default ReviewComponent;