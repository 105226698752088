import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "config/Theme";
import { Stack } from "@mui/system";

export default function KjosTextArea(props) {
  return (
    <>
     
      <Stack direction={"column"} spacing={2}>
        <label htmlFor={props.id}> {props.label} </label>
        <textarea
          style={props.style}
          defaultValue={props.defaultValue}          
          value={props.value}
          disabled={props.disabled}
          placeholder={props.placeholder}
          id={props.id}
          name={props.name}
          required={props.required}
          type={props.type}
          onChange={props.onChange}
          checked={props.checked}
          key={props.defaultValue}
        >
          
          </textarea>
      </Stack>
    </>
  );
}
