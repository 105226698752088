import { Card, CardContent, CardHeader } from '@mui/material';
import { Stack } from '@mui/system';
import { FANCY_BLUE, FANCY_GREEN } from 'config/color';
import React, { PureComponent } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


export default function KjosChart(props) {
  const {data, sx, title, dataKey} = props

    return (
      <Card sx={{width:"100%"}}>
      <CardHeader title={title}  sx={{backgroundColor: FANCY_GREEN, color: "white"}}/>
      <CardContent sx={sx}>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            right: 30,
            left: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/* <Bar dataKey="pv" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} /> */}
          <Bar dataKey={dataKey} fill={FANCY_GREEN} activeBar={<Rectangle fill="gold" stroke="purple" />} />
        </BarChart>
      </ResponsiveContainer>
      </Stack>
      </CardContent>
      </Card>
    );
}
