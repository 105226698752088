import * as React from "react";
import KjosHeader from "components/KjosHeader";

import { Box, Tab, Unstable_Grid2 as Grid, Stack } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CircuitTraitement from "pages/settings/CircuitTraitement";
import ClassDeSignalement from "pages/settings/ClassDeSignalement";
import Communes from "pages/settings/Communes";
import SourceDonnee from "pages/settings/SourceDonnee";
import SourcesDesDonnees from "pages/settings/SourcesDesDonnees";
import Habillete from "pages/settings/Habillete";
import TypeSignalement from "pages/settings/TypeSignalement";
import KjosAuthorized from "components/KjosAuthorized";
import { FANCY_BLUE } from "config/color";
import { Build } from "@mui/icons-material";
import KjosTitle from "components/KjosTitle";

const Workflow = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <KjosAuthorized permission="admin_settings">
      <KjosHeader>Workflow | ANLC - Workflow</KjosHeader>

      <Box sx={{ width: "100%", typography: "body1", py: 3 }}>
        <Stack sx={{px: 5}}>
          <KjosTitle icon={<Build />} title="Paramètrage" subtitle="Gestion du workflow" iconBgColor={FANCY_BLUE} />
        </Stack>
        <br />
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" scrollButtons variant="scrollable" allowScrollButtonsMobile>
              <Tab label="Circuit de traitement" value="1" />
              <Tab label="Type de signalements" value="2" />
              <Tab label="Groupes" value="3" />
              <Tab label="Entité mise en cause" value="4" />
              <Tab label="Source des données" value="5" />
              <Tab label="Classe de signalement" value="6" />
              <Tab label="Gestion des communes" value="7" />
              {/* <Tab label="Gestion des Autorisations" value="6" /> */}
            </TabList>
          </Box>
          <TabPanel value="1">
            <CircuitTraitement />
          </TabPanel>
          <TabPanel value="2">
             {" "}
            <TypeSignalement />{" "}
          </TabPanel>
          <TabPanel value="3">
            {" "}
            <Habillete />
          </TabPanel>
          <TabPanel value="4">
            {" "}
            <SourceDonnee />{" "}
          </TabPanel>
          <TabPanel value="5">
            {" "}
            <SourcesDesDonnees />{" "}
          </TabPanel>

          <TabPanel value="6">
            {" "}
            <ClassDeSignalement />{" "}
          </TabPanel>

          <TabPanel value="7">
            {" "}
            <Communes />{" "}
          </TabPanel>

          {/* <TabPanel value="6"> <Autorisations /> </TabPanel> */}
        </TabContext>
      </Box>
    </KjosAuthorized>
  );
};

export default Workflow;
