export default function translatePermission(permissionString) {
  if (Array.isArray(permissionString)) {
    permissionString.map(function (string) {
      if (string === "create_signalement") {
        return "créer un signalement";
      } else if (string === "read_signalement") {
        return "Lister les signalements";
      } else if (string === "update_signalement") {
        return "mettre à jour un signalement";
      } else if (string === "delete_signalement") {
        return "supprimer un signalement";
      } 
      else if (string === "reclassify_signalement") {
      return "réclassifier un signalement";
    }
      else if (string === "signalement_limite_according_locality") {
        return "Limiter les signalements selon la localité";
      }
      else if (string === "read_historique") {
        return "afficher l'historique";
      } else if (string === "delete_historique") {
        return "supprimer l'historique";
      } else if (string === "update_historique") {
        return "mettre à jour l'historique";
      } else if (string === "read_comment") {
        return "afficher un commentaire";
      } else if (string === "create_comment") {
        return "créer un commentaire";
      } else if (string === "delete_comment") {
        return "supprimer un commentaire";
      } else if (string === "update_comment") {
        return "mettre à jour un commentaire";
      } else if (string === "transfert_signalement") {
        return "transférer un signalement";
      } else if (string === "class_without_continuation") {
        return "classer un signalement sans suite";
      } else if (string === "class_without_continuation_callback") {
        return "rappeler un signalelement classé sans suite";
      } else if (string === "create_user") {
        return "créer un utilisateur";
      } else if (string === "read_user") {
        return "afficher un utilisateur";
      } else if (string === "update_user") {
        return "mettre à jour un utilisateur";
      } else if (string === "delete_user") {
        return "supprimer un utilisateur";
      } else if (string === "create_habilete") {
        return "créer une habileté";
      } else if (string === "read_habilete") {
        return "afficher une habileté";
      } else if (string === "update_habilete") {
        return "mettre à jour une habileté";
      } else if (string === "delete_habilete") {
        return "supprimer une habileté";
      } else if (string === "create_admin") {
        return "créer un administrateur";
      } else if (string === "read_admin") {
        return "afficher un administrateur";
      } else if (string === "update_admin") {
        return "mettre à jour un administrateur";
      } else if (string === "delete_admin") {
        return "supprimer un administrateur";
      } else if (string === "create_permission") {
        return "créer une permission";
      } else if (string === "read_permission") {
        return "afficher une permission";
      } else if (string === "update_permission") {
        return "mettre à jour une permission";
      } else if (string === "delete_permission") {
        return "supprimer une permission";
      } else if (string === "create_role") {
        return "créer un rôle";
      } else if (string === "read_role") {
        return "afficher un rôle";
      } else if (string === "update_role") {
        return "mettre à jour un rôle";
      } else if (string === "delete_role") {
        return "supprimer un rôle";
      } else if (string === "export_rapport") {
        return "exporter un rapport";
      } else if (string === "all_permission") {
        return "toutes les permissions";
      } else if (string === "show_dashboard") {
        return "afficher le tableau de bord";
      } else if (string === "admin_settings") {
        return "paramétrage";
      } else if (string === "edit_signalement") {
        return "modifier un signalement";
      } else if (string === "edit_habilete") {
        return "modifier une habileté";
      } else if (string === "edit_comment") {
        return "modifier un commentaire";
      } else if (string === "edit_role") {
        return "modifier un rôle";
      } else if (string === "edit_user") {
        return "modifier un utilisateur";
      } else if (string === "edit_permission") {
        return "modifier une permission";
      } else if (string === "edit_admin") {
        return "modifier un administrateur";
      } else if (string === "complaints_management") {
        return "recouvrement des plaintes";
      } 
      else if (string === "CMS_management") {
        return "gestion du CMS";
      } 
      else if (string === "read_avis") {
        return "Voir les avis";
      } 
      else if (string === "articles_management") {
        return "gestion des articles";
      } 
      else if (string === "complaints_management") {
        return "gestion des plaintes";
      } 
      else if (string === "report_management") {
        return "gestion des rapports";
      } 
      else {
        return string;
      }
    });
  } else {
    if (permissionString === "create_signalement") {
      return "créer un signalement";
    } else if (permissionString === "read_signalement") {
      return "Lister les signalements";
    } else if (permissionString === "update_signalement") {
      return "mettre à jour un signalement";
    } else if (permissionString === "signalement_limite_according_locality") {
      return "Limiter les signalements selon la localité";
    }
    else if (permissionString === "delete_signalement") {
      return "supprimer un signalement";
    }
    else if (permissionString === "read_historique") {
      return "afficher l'historique";
    } else if (permissionString === "delete_historique") {
      return "supprimer l'historique";
    } else if (permissionString === "update_historique") {
      return "mettre à jour l'historique";
    } else if (permissionString === "read_comment") {
      return "afficher un commentaire";
    } else if (permissionString === "create_comment") {
      return "créer un commentaire";
    } else if (permissionString === "delete_comment") {
      return "supprimer un commentaire";
    } else if (permissionString === "update_comment") {
      return "mettre à jour un commentaire";
    } else if (permissionString === "transfert_signalement") {
      return "transférer un signalement";
    } else if (permissionString === "class_without_continuation") {
      return "classer un signalement sans suite";
    } else if (permissionString === "class_without_continuation_callback") {
      return "rappeler un signalelement classé sans suite";
    } else if (permissionString === "create_user") {
      return "créer un utilisateur";
    } else if (permissionString === "read_user") {
      return "afficher un utilisateur";
    } else if (permissionString === "update_user") {
      return "mettre à jour un utilisateur";
    } else if (permissionString === "delete_user") {
      return "supprimer un utilisateur";
    } else if (permissionString === "create_habilete") {
      return "créer une habileté";
    } else if (permissionString === "read_habilete") {
      return "afficher une habileté";
    } else if (permissionString === "update_habilete") {
      return "mettre à jour une habileté";
    } else if (permissionString === "delete_habilete") {
      return "supprimer une habileté";
    } else if (permissionString === "create_admin") {
      return "créer un administrateur";
    } else if (permissionString === "read_admin") {
      return "afficher un administrateur";
    } else if (permissionString === "update_admin") {
      return "mettre à jour un administrateur";
    } else if (permissionString === "delete_admin") {
      return "supprimer un administrateur";
    } else if (permissionString === "create_permission") {
      return "créer une permission";
    } else if (permissionString === "read_permission") {
      return "afficher une permission";
    } else if (permissionString === "update_permission") {
      return "mettre à jour une permission";
    } else if (permissionString === "delete_permission") {
      return "supprimer une permission";
    } else if (permissionString === "create_role") {
      return "créer un rôle";
    } else if (permissionString === "read_role") {
      return "afficher un rôle";
    } else if (permissionString === "update_role") {
      return "mettre à jour un rôle";
    } else if (permissionString === "delete_role") {
      return "supprimer un rôle";
    } else if (permissionString === "export_rapport") {
      return "exporter un rapport";
    } else if (permissionString === "all_permission") {
      return "toutes les permissions";
    } else if (permissionString === "show_dashboard") {
      return "afficher le tableau de bord";
    } else if (permissionString === "admin_settings") {
      return "paramétrage";
    } else if (permissionString === "edit_signalement") {
      return "modifier un signalement";
    } else if (permissionString === "edit_habilete") {
      return "modifier une habileté";
    } else if (permissionString === "edit_comment") {
      return "modifier un commentaire";
    } else if (permissionString === "edit_role") {
      return "modifier un rôle";
    } else if (permissionString === "edit_user") {
      return "modifier un utilisateur";
    } else if (permissionString === "edit_permission") {
      return "modifier une permission";
    } else if (permissionString === "edit_admin") {
      return "modifier un administrateur";
    } 
    else if (permissionString === "complaints_management") {
      return "recouvrement des plaintes";
    }
    else if (permissionString === "CMS_management") {
      return "gestion du CMS";
    } 
    else if (permissionString === "read_avis") {
      return "Voir les avis";
    } 
    else if (permissionString === "articles_management") {
      return "gestion des articles";
    } 
    else if (permissionString === "complaints_management") {
      return "gestion des plaintes";
    } 
    else if (permissionString === "report_management") {
      return "gestion des rapports";
    } 
    else if (permissionString === "reclassify_signalement") {
      return "réclassifier un signalement";
    } 
    else {
      return permissionString;
    }
  }
}
