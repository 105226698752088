import { setOpen, setUpdate } from "store/AuthenticationReducer";
import { setLoading } from "store/SignalementReducer";
import { Button, SvgIcon } from "@mui/material";
import ArrowPathIcon from "@heroicons/react/24/solid/ArrowPathIcon";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/system";
import { useEffect } from "react";

export default function KjosSyncButton() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.signalement);
  useEffect(() => {
    return () => {
      dispatch(setLoading(false));
    };
  }, [dispatch]);
  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"}>
      <Button
        color="inherit"
        size="small"
        startIcon={
          <SvgIcon fontSize="small">
            <ArrowPathIcon />
          </SvgIcon>
        }
        onClick={() => {
          dispatch(setUpdate());
          dispatch(setLoading(true));
        }}
      >
        Sync
      </Button>
      {loading && <span>Loading...</span>}
    </Stack>
  );
}
