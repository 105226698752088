import { Button, SvgIcon } from "@mui/material";
import ArchiveBoxIcon from "@heroicons/react/24/solid/ArchiveBoxIcon";
import { FANCY_ORANGE } from "config/color";

export default function ClasseSansuiteButtonDisabled(props) {
  return (
    <>
      {/* Button */}
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <ArchiveBoxIcon />
          </SvgIcon>
        }
        variant="contained"
        color="info"
        disabled={true}
        sx={{ bgcolor: FANCY_ORANGE, color: "white" }}
        >
        Classer sans suite
      </Button>
    </>
  );
}
