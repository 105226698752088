import axios from "axios";
import { ADMIN_PASSWORD_LINK_URL, ADMIN_PASSWORD_URL, BASE_URL, BASE_URL_API, CSRF_COOKIES, REGISTER_URL } from "config/RoutesUrl";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import useLocalSorage from "./useLocalSorage";
import { SIGNIN_PATH } from "config/PathUrl";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useHttp from "./useHttp";
import useAlert from "./useAlert";
import { setAlert } from "store/SignalementReducer";
import { FANCY_BLUE } from "config/color";

function useRegister(tokenUrl = BASE_URL + CSRF_COOKIES, registerURL = BASE_URL_API + REGISTER_URL) {
  const router = useNavigate();
  const [getLocalStorage, removeLocalStorage, setLocalStorage] = useLocalSorage();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [datas, setDatas] = useState(null)
  const {postData} = useHttp();
  const {errorAlert} = useAlert();
  const dispatch = useDispatch();

  const handleFormSubmit = useCallback(
    async (param, action = SIGNIN_PATH, redirect = true) => {
      param.preventDefault(); // prevent default
      setLoading(true); // start loader
      const formData = new FormData(param.currentTarget); // get form data
      const data = {
        email: formData.get("email"),
        password: formData.get("password"),
        prenom: formData.get("prenom"),
        nom: formData.get("nom"),
        habilete_id: formData.get("habilete_id"),
        // contact: formData.get("contact"),
        password_confirmation: formData.get("password_confirmation"),
        // habilitations: formData.get("habilitations"),
        // date_naissance: formData.get("date_naissance"),
        // adresse: formData.get("adresse"),
        // ville: formData.get("ville"),
        // pays: formData.get("pays"),
        // photo: formData.get("photo"),
        // genre: formData.get("genre"),
        role: "admin",
      }; // get form data
      // axios.defaults.withCredentials = true;
      try {
        // await axios.get(tokenUrl); // ping the route
        const request = await axios.post(registerURL, data); // get user
        const response = request;
        if (response?.data?.error) {
          setError(response?.data?.error); // save error
          setDatas(response?.data?.data ?? response?.data); // save error data
        } else {
          setLocalStorage("isRegister", true); //success resgister
          redirect && router(action);
        }
        setLoading(false); // stop loader
      } catch (error) {
        setError({ serverError: true, error: error });
      }
    },
    [registerURL, router, setLocalStorage]
  );

  const setPassword = useCallback( 
    async (data) => {
      const request = await postData( data, ADMIN_PASSWORD_URL);
      console.log(request)
      if(request?.error) {
        errorAlert(request?.message);
        return
      }
    dispatch(setAlert({ open: true, message: "Mot de passe modifié avec succès. Veuillez vous connecter pour continuer.", color: FANCY_BLUE }));
      
      router(SIGNIN_PATH, {state: {password_changed: true}} );

    },[postData])

  const checkPasswordLink = useCallback( 
    async (data) => {
      const request = await postData( data, ADMIN_PASSWORD_LINK_URL);
      return request;
    },[postData])

  useEffect(() => {
  }, [datas, error, loading]);

  return {handleFormSubmit, error, datas, loading, checkPasswordLink, setPassword};
}

export default useRegister;
