   import { Search } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { useState } from 'react';

   export default function KjosSearch(props) {
      const { onSearch, placeholder } = props;
      const [isExpanded, setIsExpanded] = useState(false);

  const handleSearchIconClick = () => {
    setIsExpanded(!isExpanded);
  };


   return (
      <Stack className="search-form" direction="row" spacing={1}>
      <input
        type="text"
        className={`search-input ${isExpanded ? 'expanded' : 'hidden'}`}
        placeholder={placeholder}
        onChange={onSearch}
      />
      <IconButton onClick={handleSearchIconClick} sx={{ color: "white" }} className="search-icon" aria-label="search">
         <Search />
      </IconButton>
    </Stack>
   )
}