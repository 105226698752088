import axios from "axios";

class KjosHttp {
  static get config() {
    const user = JSON.parse(localStorage.getItem("admin"));
    return { headers: { Accept: "application/json", Authorization: `Bearer ${user?.data?.token}` } }
  }
  async get(url, config = KjosHttp.config) {
      try{
        const response = await axios.get(url, config)
        return response
      }catch(error){
        return { serverError: true, error: error };
      }
  }

  async post(url, data = null, config = KjosHttp.config) {
    try{
      const response = await axios.post(url, data, config)
    return response
    }catch(error){
      return { serverError: true, error: error };
    }
  }

  async put(url, data = null, config = KjosHttp.config) {
    try{
      const response = await axios.put(url, data, config)
    return response
    }catch(error){
      return { serverError: true, error: error };
    }
  }

  async delete(url, config = KjosHttp.config) {
    try{
      const response = await axios.delete(url, config)
    return response
    }catch(error){
      return { serverError: true, error: error };
    }
  }
}

export default KjosHttp;
