import { useCallback } from "react";
import { setAlert } from "store/SignalementReducer";
import { useDispatch, useSelector } from "react-redux";
import KjosAlert from "components/KjosAlert";
import scrollToTop from "controller/scrollToTop";
import { FANCY_BLUE, FANCY_GREEN, FANCY_ORANGE, FANCY_PINK } from "config/color";

export default function useAlert() {
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.signalement);

  const createAlert = useCallback((message = "Enregistré avec succès") => {
    dispatch(setAlert({ open: true, message: message, color: FANCY_BLUE }));
    scrollToTop();

    return <KjosAlert label={message} />;
  }, []);

  const updateAlert = useCallback((message = "Mis à jour avec succès") => {
    dispatch(setAlert({ open: true, message: message, color: FANCY_GREEN }));
    scrollToTop();

    return <KjosAlert label={alert.message} />;
  }, []);

  const deleteAlert = useCallback((message = "Supprimer avec succès") => {
    dispatch(setAlert({ open: true, message: message, color: FANCY_ORANGE }));
    scrollToTop();

    return <KjosAlert label={alert.message} />;
  }, []);


  const sentAlert = useCallback((message = "Envoyé avec succès") => {
    dispatch(setAlert({ open: true, message: message, color: FANCY_PINK }));
    scrollToTop();

    return <KjosAlert label={alert.message} />;
  }, []);

  const errorAlert = useCallback((message = "Une erreur s'est produite") => {
    dispatch(setAlert({ open: true, message: message, color: "red" }));
    scrollToTop();

    return <KjosAlert label={alert.message} />;
  }, []);

  return {
    createAlert,
    updateAlert,
    deleteAlert,
    sentAlert,
    errorAlert,
  };
}
