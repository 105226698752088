import KjosChip from "components/KjosChip";
import { FANCY_ORANGE } from "config/color";

export default function formatUserName(item) {
  return (
    <>
      {item?.user ? item?.user?.nom + " " + item?.user?.prenom : (item?.admin ?
      <span>
        {item?.admin?.nom} {item?.admin?.prenom} <KjosChip label="Admin" color={FANCY_ORANGE} size="small" variant="contained" />
      </span> :"Anonyme")}
    </>
  );
}
