import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material';
import BellIcon from '@heroicons/react/24/solid/BellIcon';
import { FANCY_GREEN } from 'config/color';
import ErrorIcon from '@mui/icons-material/Error'; // This represents the "Avis" icon

export const Avis = (props) => {
  const { value, sx } = props;

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              gutterBottom
              variant="overline"
              sx={{
                color:"white",
                fontWeight:"bold"
              }}
            >
              Avis
            </Typography>
            <Typography variant="h6">
              {value}
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: 'white',
              height: 56,
              width: 56,
              boxShadow: '0 1px 6px rgba(0, 0, 0, 0.24)',
            }}
          >
            <SvgIcon sx={{color: FANCY_GREEN,}}>
              <ErrorIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
        {/* <Box sx={{ mt: 3 }}>
          <LinearProgress
            value={value}
            variant="determinate"
          />
        </Box> */}
      </CardContent>
    </Card>
  );
};

export default Avis;