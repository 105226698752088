const getArrayItemsById = (id, data) => {
  var items = null;
  data?.map((item) => {
    if (item.id === id) {
      items = item;
      return false
    }
  });

  return items;
};

export default getArrayItemsById;
