import { Button, SvgIcon, Typography } from "@mui/material";
import ArchiveBoxIcon from "@heroicons/react/24/solid/ArchiveBoxIcon";
import KjosDialog2 from "components/KjosDialog2";
import { Stack } from "@mui/system";
import { setUpdate, setOpen } from "store/AuthenticationReducer";
import { useDispatch, useSelector } from "react-redux";
import useSignalement from "hooks/useSignalement";
import useActionButton from "hooks/useActionButton";
import useHistorique from "hooks/useHistorique";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useEffect } from "react";
import { ADMIN_SIGNALLEMENT_RECLASSIFICATION_URL, ADMIN_SIGNALLEMENT_URL, ADMIN_TYPE_SIGNALEMENT_URL } from "config/RoutesUrl";
import usePermission from "hooks/usePermission";
import useHttp from "hooks/useHttp";
import { setAlert } from "store/SignalementReducer";
import { CREATE_COLOR, DELETE_COLOR, FANCY_GREEN, FANCY_ORANGE } from "config/color";
import ReplayIcon from "@mui/icons-material/Replay";
import KjosInput from "components/KjosInput";
import Dialog from "components/Dialog";

export default function ReclassifyButton(props) {
  const { signalement, signalementId } = props;
  const [motif, setMotif] = useState("");
  const dispatch = useDispatch();
  const { disableReclassifyButton } = useActionButton();
  const [disabled, setDisabled] = useState(false);
  const [typeSignalement, setTypeSignalement] = useState([]);
  const { getOneData, getData, putData } = useHttp();
  const { update } = useSelector((state) => state.authentication); // get result state
  const [classeInputValue, setClasseInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOnClasseChange = (e) => {
    setClasseInputValue(e.target.value);
  };

  const body = (
    <Stack direction={"column"} spacing={2} justifyContent={"center"} sx={{ width: "100%" }}>
      {
        loading && <Typography variant="body2">Chargement...</Typography> 
      }
      <KjosInput select={true} options={typeSignalement} onChange={handleOnClasseChange} value={classeInputValue} width="100%" focused={true} label="Type de signalement"></KjosInput>
    </Stack>
  );

  const handleReclassify = () => {
    setMotif("reclassify_signalement");
    dispatch(setOpen(true));
  };


  const submitCallback = async (e) => {
    if (motif === "reclassify_signalement") {
      setLoading(true);

      const data = {
        type_signalement_id_chosen: classeInputValue,
      }
      const resp = await putData(data, ADMIN_SIGNALLEMENT_RECLASSIFICATION_URL + "/" + signalementId);
      console.log('resp++++++++++++++++++++++++++++++++++++++', resp)
      if (resp?.error) {
        dispatch(setAlert({ open: true, message: "Une erreur est survenue", color: DELETE_COLOR }));
        dispatch(setUpdate());
      }else{
        dispatch(setAlert({ open: true, message: "Signalement reclassifié avec succès", color: CREATE_COLOR }));
        dispatch(setUpdate());
        dispatch(setOpen(false));
        setLoading(false);        
      }
    }
    dispatch(setUpdate());
  };

  useEffect(() => {
    disableReclassifyButton(signalementId).then((resp) => {
      setDisabled(resp);
    });

    getData(ADMIN_TYPE_SIGNALEMENT_URL).then((type) => {
      const typeSign = type?.data?.map((item) => ({
        label: item.libelle,
        classe_signalement: item.classe_signalement?.libelle,
        value: item.id,
        id: item.id,
      }));

      setTypeSignalement(typeSign);
    });

  }, [disableReclassifyButton, getOneData, signalementId, update]);

  return (
    <>
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <ReplayIcon />
          </SvgIcon>
        }
        variant="contained"
        color="error"
        onClick={handleReclassify}
        disabled={disabled}
        sx={{ bgcolor: FANCY_ORANGE, color: "white" }}
      >
        Réclassifier
      </Button>

      {/* Dialogue */}
      <Dialog submitCallback={submitCallback} body={body} title="Réclassification" button="Valider" />

    </>
  );
}
