import { useCallback } from "react";
import useLocalSorage from "./useLocalSorage";
import KjosHttp from "controller/KjosHttp";
import { BASE_URL_API, LOGOUT_URL } from "config/RoutesUrl";
import { SIGNIN_PATH } from "config/PathUrl";
import useHttp from "./useHttp";
import { useNavigate } from "react-router-dom";

function useDeconnect() {
  const [getLocalStorage, removeLocalStorage, setLocalStorage] = useLocalSorage();
  const {postData} = useHttp();
  const route = useNavigate();
  const deconnect = useCallback(async (e, url = BASE_URL_API + LOGOUT_URL, item = "admin", redirect = SIGNIN_PATH) => {
    const token = getLocalStorage(item)?.token; // get token
    const config = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }; // get config
    try {
      const req = await postData(null, url, config); // ping the route
      // const req = await new KjosHttp().post(url, null, config); // ping the route
      if (!req?.data?.error) {
        removeLocalStorage(item);
        route(redirect);
      }
    } catch (error) {
      removeLocalStorage(item);
      route(redirect);
    }
  }, [getLocalStorage, postData, removeLocalStorage, route]);

  return {deconnect};
}
export default useDeconnect;
