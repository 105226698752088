import { Container} from "@mui/material";
import { Link } from "react-router-dom";
export default function Error404() {
  return (
    <section className="page_404">
   
      <Container  className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Vous vous êtes égaré</h3>

                <p>La page que vous recherchez n'est pas disponible!</p>

                <Link to={"/"} className="link_404">
                  Accueil
                </Link>
            </div>
              </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
