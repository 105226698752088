import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import getDefaultImage from "controller/getDefaultImage";
import { PhotoCamera } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import { useState } from "react";

export default function KjosInputFile2(props) {
  const { photoRef, accept, photoCleanedRef, onChange, icon, tooltip, maxlength } = props;
  const [photosrc, setPhotosrc] = useState(null);
  const [imageExists, setImageExists] = useState(true);
  const handleImageError = () => {
    setImageExists(false);
  };

  const handleOnChange = (e) => {
    if (photoCleanedRef) photoCleanedRef.current = false;
    // setPhotosrc(URL.createObjectURL(e.target.files[0]))
    const file = (
      <Stack direction={"row"} spacing={1}>
        {" "}
        <Typography>{e.target.files[0].name} {" "} {(e.target.files[0].size > 20971520 ? <Typography color={"error"}>Fichier trop volumineux (taille max : 20MB) </Typography> : "")}</Typography>
        <IconButton
          onClick={() => {
            photoRef.current.value = "";
            if (photoCleanedRef) photoCleanedRef.current = true;
            onChange("");
          }}
        >
          <Delete sx={{fontSize: "1rem"}} />
        </IconButton>
      </Stack>
    );

    onChange(file);
  };

  return (
    <Stack>
      <input
        type="file"
        name="photo"
        id="photo"
        ref={photoRef}
        hidden
        onChange={handleOnChange}
        accept={accept}
        maxLength={maxlength ?? "20971520"}
      />
        <IconButton onClick={() => photoRef.current.click()}>{icon}</IconButton>{" "}
    </Stack>
  );
}
