import { useState } from "react";
import useLocalSorage from "./useLocalSorage";
import { useEffect } from "react";
import { useCallback } from "react";
import { avatar_anonyme_boy } from "config/image";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "store/AuthenticationReducer";

function useRole(user ="admin",role) {
  const [getLocalStorage, removeLocalStorage, setLocalStorage] = useLocalSorage();

   const userRole = getLocalStorage(user);
  
  return  role === userRole?.user?.role ? true:false;
}

export default useRole;
