import KjosSnacBar from "components/KjosSnackBar";
import { useState } from "react";
import KjosDialog from "components/KjosDialog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "hooks/useHttp";
import KjosInput from "components/KjosInput";
import { CLASSE_SIGNALEMENT_CREATE_URL, CLASSE_SIGNALEMENT_GET_URL } from "config/RoutesUrl";
import { setEditResult, setPostResult } from "store/AuthenticationReducer";

const Dialog = (props) => {
  const {submitCallback, body, title, button} = props
   const {postResult, editResult} = useSelector(state => state.authentication)
   const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
 
   const handleOnclose = () => {
     setOpen(false);
     dispatch(setEditResult(null))
     dispatch(setPostResult(null))
   }
 
 
   useEffect(() => {
     postResult && setOpen(true) 
     editResult && setOpen(true) 

     return () => {
      setOpen(false) 
     }
   },[postResult, editResult, dispatch])
 
   return (
     <>
       {/* Dialogue */}
       <KjosDialog title={title} body={body} button={button} submitCallback={submitCallback} />
 
       {/* Backlog */}
       {/* <KjosSnacBar
       open={open}
       onClose={handleOnclose}
       message={((postResult?.message?.libelle && postResult?.message?.libelle[0]) ?? (editResult?.message?.libelle && postResult?.message?.libelle[0])) ??(postResult?.message ??  editResult?.message)}
       autoHideDuration={6000}
       severity={((postResult?.message?.libelle && postResult?.message?.libelle[0]) ?? (editResult?.message?.libelle && editResult?.message?.libelle[0])) ? "error" : "success"}
       /> */}
       
     </>
   );
 };

 export default Dialog