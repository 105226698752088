import { createSlice } from "@reduxjs/toolkit";

const initialState = {
      auth: {},
      loading: false,
      error: false,
      isauth : false,
      user : {}, 
      open : false,
      postResult:null,
      getResult:null,
      deleteResult:null,
      editResult:null,
      oneDataResult:null,
      update:0,
}
const authenticationSlice = createSlice({
   name: "authentication",
   initialState,
   reducers: {
      setAuth: (state, action) => {
         state.auth = action.payload
      },
      setloading: (state, action) => {
         state.loading = action.payload
      },
      seterror: (state, action) => {
         state.error = action.payload
      },
      setIsAuth: (state, action) => {
         state.isauth = action.payload
      },
      setUser: (state, action) => {
         state.user = action.payload
      },
      setOpen: (state, action) => {
         state.open = action.payload
      },
      setPostResult: (state, action) => {
         state.postResult = action.payload
      },
      setGetResult: (state, action) => {
         state.getResult = action.payload
      },
      setDeleteResult: (state, action) => {
         state.deleteResult = action.payload
      },
      setEditResult: (state, action) => {
         state.editResult = action.payload
      },
      setOneDataResult: (state, action) => {
         state.oneDataResult = action.payload
      },
      setUpdate: (state, action) => {
         state.update ++;
      },
   }
})
export const { setAuth, setloading, seterror, setIsAuth, setUser, setOpen, setPostResult, setGetResult, setDeleteResult, setEditResult, setOneDataResult, setUpdate } = authenticationSlice.actions
export default authenticationSlice.reducer