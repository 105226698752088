import { Box, Button, Container, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import KjosList2 from "components/KjosList2";
const { json_to_sheet, book_append_sheet } = utils;
import { writeFile, utils } from "xlsx";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "hooks/useHttp";
import useAvis from "hooks/useAvis";
import useUser from "hooks/useUser";
import useHistorique from "hooks/useHistorique";
import { setLoading, setPage, setRowPerpage } from "store/SignalementReducer";
import KjosAlert from "components/KjosAlert";
import { useNavigate } from "react-router-dom";
const workbook = utils.book_new();
import { FANCY_GREEN } from "config/color";
import KjosInput2 from "components/KjosInput2";
const AvisLayout = () => {
  const dispatch = useDispatch();
  const { getAvis, getAvisForXlsx } = useAvis(); // get data from server
  const {  update } = useSelector((state) => state.authentication); // get result state
  
  const [avis, setAvis] = useState([]); // open snackbar
  const [dateDebut, setDateDebut] = useState(null);
  const [dateFin, setDateFin] = useState(null);

  const { page, rowsPerPage, alert } = useSelector((state) => state.signalement);
  /* Table Header */
  const header = {
    // date: "Date",
    // id: "#ID",
    utilisateur: "Utilisateur",
    commentaire: "Commentaire",
    note: "Note",
    version_app: "Version Application",
    date_ajout: "Date d'ajout",
  };

  // Function
  const handleDateDebut = async (e) => {
    setDateDebut(e.target.value);
    const av = await getAvis(page + 1, rowsPerPage, "order=desc&order_by=created_at&date_debut=" + e.target.value + "&date_fin=" + dateFin);
      setAvis(av);
      dispatch(setLoading(false)); // setLoading
  };

  const handleDateFin = async (e) => {
    setDateFin(e.target.value)
    const av = await getAvis(page + 1, rowsPerPage, "order=desc&order_by=created_at&date_debut=" + dateDebut + "&date_fin=" + e.target.value);
    setAvis(av);
    dispatch(setLoading(false)); // setLoading
  };

  const generateReport = async () => {
    const av = await getAvisForXlsx(null, null, "order=desc&order_by=created_at&date_debut=" + dateDebut + "&date_fin=" + dateFin);
  
    const worksheet = json_to_sheet(av);
  
    // Check if "Sheet1" already exists and rename if necessary
    let sheetName = "Sheet1";
    if (workbook.SheetNames.includes(sheetName)) {
      let sheetIndex = 1;
      while (workbook.SheetNames.includes(sheetName + sheetIndex)) {
        sheetIndex++;
      }
      sheetName += sheetIndex;
    }
  
    book_append_sheet(workbook, worksheet, sheetName);
    writeFile(workbook, dateDebut + "-" + dateFin + ".xlsx");
  }

  useEffect(() => {
    async function fetchData() {
      const av = await getAvis(page + 1, rowsPerPage, "order=desc&order_by=created_at");
      setAvis(av);
      dispatch(setLoading(false)); // setLoading
    }
    fetchData(); // get data when component is mounted
  }, [dispatch, getAvis, page, rowsPerPage, update]);

  useEffect(() => {
    return () => {
      dispatch(setPage(0));
      dispatch(setRowPerpage(5));
    };
  }, [dispatch]);

  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
      <KjosAlert label={alert.message} />
      <Container maxWidth={"xl"}>
        <Stack spacing={3} direction={"column"}>
          <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"flex-end"}>
            <Stack direction={{ xs: "column", md: "row" }} alignItems={"flex-end"} spacing={2}>
              
              {/* Date debut */}
              <KjosInput2 type="date" id="date_debut" label="Date début" name="date_debut" width={"100%"} max={new Date().toISOString().split("T")[0]} value={dateDebut} onChange={handleDateDebut} />

              {/* Date fin */}
              <KjosInput2 type="date" id="date_fin" label="Date fin" name="date_fin" width={"100%"} min={""} max={new Date().toISOString().split("T")[0]} value={dateFin} onChange={handleDateFin} />

            </Stack>
            <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <IosShareIcon />
                  </SvgIcon>
                }
                variant="contained"       
                onClick={generateReport}
                sx={{ bgcolor: FANCY_GREEN, color: "white", height: "40px" }}
              >
                Générer le Rapport
              </Button>

            </Stack>
          </Stack>

          <Stack>
            <KjosList2 data={avis} header={header} isCheckBox={false} key={page} title="Liste des aviss" search={false} />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default AvisLayout;
