import { createSlice } from "@reduxjs/toolkit";

const initialState = {
page: 0,
rowsPerPage: 5,
count:0, 
countNotification:0, 
loading: false,
alert: {
   open: false,
   message: "",
   color: "success",
}
}
const signalementSlice = createSlice({
   name: "signalement",
   initialState,
   reducers: {
      setPage: (state, action) => {
         state.page = action.payload
      },
      setRowPerpage: (state, action) => {
         state.rowsPerPage = action.payload
      },
      setCount: (state, action) => {
         state.count = action.payload
      },
      setCountNotification: (state, action) => {
         state.countNotification = action.payload
      },
      setLoading: (state, action) => {
         state.loading = action.payload
      },
      setAlert: (state, action) => {
         state.alert = action.payload
      }
   }
})
export const { setPage, setRowPerpage, setCount, setLoading, setAlert, setCountNotification } = signalementSlice.actions
export default signalementSlice.reducer