import { useCallback } from "react";
import useHttp from "hooks/useHttp";
import useUser from "./useUser";
import { ADMIN_INDEX_TRAITEMENT, ADMIN_HABILETE_URL, ADMIN_TYPE_SIGNALEMENT_URL, ADMIN_SIGNALLEMENT_URL, ADMIN_HISTORIQUE_URL, ADMIN_ADMIN_ACTION_URL, ADMIN_SIGNALLEMENT_QUERY_URL } from "config/RoutesUrl";
import getArrayItemsById from "controller/getArrayItemsById";
import useRole from "./useRole";
import { Chip } from "@mui/material";
import useStatut from "./useStatut";
import { useDispatch } from "react-redux";
import { setCount } from "store/SignalementReducer";
import KjosChip from "components/KjosChip";
import { FANCY_ORANGE } from "config/color";
import formatUserName from "controller/formatUserName";
import usePermission from "./usePermission";
import formatDate from "controller/formatDate";

const useSignalement = () => {
  const { getData, postData, putData, deleteData, getOneData, deleteData2 } = useHttp(); // get data from server
  const { getUser } = useUser();
  const isSuperAdmin = useRole("admin", "superadmin");
  const { color, label } = useStatut();
  const dispatch = useDispatch();
  const { hasPermission } = usePermission();

  const createSignalement = useCallback(
    async (data, type_signalement_url = ADMIN_TYPE_SIGNALEMENT_URL, index_traitement_url = ADMIN_INDEX_TRAITEMENT) => {
      const creatSign = await postData(data, ADMIN_SIGNALLEMENT_URL);
      if (creatSign?.error) {
        return {
          error: true,
          message: "Erreur de création",
        };
      } else {
        // on recupère habilete_position du signalement, si habilete_position === longueur classe signalement on ne transfert plus
        const habilete_position = creatSign?.data?.habilete_position;
        const habiletes = creatSign?.data?.classe?.habiletes;
        // recuperer la classe [[1,2,3], [1,2], [5,7,8]] du traitement suivant
        if (Array.isArray(habiletes)) {
          if (habilete_position < habiletes.length - 1) {
            // const current_habiletes = habiletes[habilete_position + 1]; //code original
            const current_habiletes = habiletes[habilete_position]; //code de test
            console.log(current_habiletes);

            if (Array.isArray(current_habiletes)) {
              current_habiletes.forEach(async (item) => {
                const data = {
                  habilete_id: item,
                  signalement_id: creatSign?.data?.id,
                };
                const data2 = {
                  habilete_id: item,
                  signalement_id: creatSign?.data?.id,
                  action: "create",
                };
                await postData(data, index_traitement_url);

                // On logs
                await postData(data2, ADMIN_ADMIN_ACTION_URL);
              });
            } else {
              const data = {
                habilete_id: current_habiletes,
                signalement_id: creatSign?.data?.id,
              };
              const data2 = {
                habilete_id: current_habiletes,
                signalement_id: creatSign?.data?.id,
                action: "create",
              };
              await postData(data, index_traitement_url);
              await postData(data2, ADMIN_ADMIN_ACTION_URL);
            }
          }
        }

        return {
          error: false,
          message: "Signalement enregistré avec succès",
          data: creatSign?.data,
        };
      }
    },
    [postData]
  );

  const updateSignalement = useCallback(
    async (id_signalement, data, signalement_url = ADMIN_SIGNALLEMENT_URL, historique_url = ADMIN_HISTORIQUE_URL) => {
      // On recupère la liste des commentaires de la table historique. On vérifie si le signalement est sujet de commentaire avant de le classer sans suite

      if (data?.statut === "without_continuation") {
        const historique = await getOneData(id_signalement, historique_url);
        if (!historique?.error) {
          if (historique?.libelle === null) {
            return {
              error: true,
              message: "Vous devez effectuer un commentaire avant de poursuivre",
            };
          } else {
            // on retourn le résultat
            const classeSignalement = await putData(data, signalement_url + "/" + id_signalement);
            return classeSignalement;
          }
        } else {
          return { error: true, message: "Une erreur c'est produite" };
        }
      } else {
        // on retourn le résultat
        const classeSignalement = await putData(data, signalement_url + "/" + id_signalement);
        return classeSignalement;
      }
    },
    [getOneData, putData]
  );

  const getAdminSignalement = useCallback(
    async (page = 1, per_page = 5, query = null) => {
      // on recupère l'utilisateur
      const user = getUser("admin")?.user;
      // on recupère son habiletés
      const habilete_id = user?.habilete?.id ?? user?.habilete_id;
      const data = {
        habilete_id: habilete_id,
      };
      // on recupère tous les signalements dans la table index_traitement selon habilete_id
      var req = [];

      if (isSuperAdmin) {
        const sign = query ? (await getData(ADMIN_SIGNALLEMENT_URL + "?page=" + page + "&per_page=" + per_page + "&" + query))?.data : (await getData(ADMIN_SIGNALLEMENT_URL + "?page=" + page + "&per_page=" + per_page))?.data;

        dispatch(setCount(sign?.total));
        const habil = (await getData(ADMIN_HABILETE_URL))?.data;
        const typeSignAll = await getData(ADMIN_TYPE_SIGNALEMENT_URL);
        console.log(sign);
        req = sign?.data?.map((item) => {
          return {
            code: item?.code,
            created_at: formatDate(item?.created_at),
            date: item?.date,
            type: getArrayItemsById(item?.type_signalement?.id, typeSignAll?.data)?.libelle,
            id: item?.id,
            user: formatUserName(item),
            localites: item?.commune?.libelle,
            workflow: <KjosChip label={label(item?.statut)} color={color(item?.statut)} size="small" variant="contained" />,
            // classe: (
            //   <Circuit
            //     classe={
            //       getArrayItemsById(item?.type_signalement?.id, typeSignAll?.data)?.classe_signalement
            //     }
            //     habiletes={habil}
            //   />
            // ),
          };
        });
      } else {
        const sign = query ? await getData(ADMIN_ADMIN_ACTION_URL + "?page=" + page + "&per_page=" + per_page + "&habilete_id=" + habilete_id + "&" + query) : await getData(ADMIN_ADMIN_ACTION_URL + "?page=" + page + "&per_page=" + per_page + "&habilete_id=" + habilete_id);

        dispatch(setCount(sign?.total));
        // const habil = (await getData(ADMIN_HABILETE_URL))?.data;
        // const typeSignAll = await getData(ADMIN_TYPE_SIGNALEMENT_URL);

        req = sign?.data.map((item) => {
          return {
            code: item?.signalement?.code,
            date: item?.signalement?.date,
            created_at: formatDate(item?.signalement?.created_at),
            type: item?.signalement?.type_signalement?.libelle,
            id: item?.signalement?.id,
            user: item?.signalement?.user ? item?.signalement?.user?.nom + " " + item?.signalement?.user?.prenom : item?.signalement?.admin ? <KjosChip label={"Admin"} color={"orange"} size="small" variant="contained" /> : "Anonyme",
            localites: item?.signalement?.commune?.libelle,
            workflow: <KjosChip label={label(item?.signalement?.statut)} color={color(item?.signalement?.statut)} size="small" variant="contained" />,
            // classe: (
            //   <Circuit
            //     classe={
            //       getArrayItemsById(item?.type_signalement?.id, typeSignAll?.data)?.classe_signalement
            //     }
            //     habiletes={habil}
            //   />
            // ),
          };
        });
      }

      // on retourn le résultat
      return req;
    },
    [color, dispatch, getData, getUser, isSuperAdmin, label]
  );

  const getAllSignalement = useCallback(
    async (page = 1, per_page = 5, query = null) => {
      // on recupère l'utilisateur
      let req;
      let sign;
      const authorized = (await hasPermission("complaints_management"))?.authorized;
      const authorized_report = (await hasPermission("report_management"))?.authorized;
      if (authorized || authorized_report) {
        if (page == null && per_page == null) {
          sign = query ? (await getData(ADMIN_SIGNALLEMENT_URL + "?" + query))?.data : (await getData(ADMIN_SIGNALLEMENT_URL))?.data;
          dispatch(setCount(sign?.total));

          const habil = (await getData(ADMIN_HABILETE_URL))?.data;
          const typeSignAll = await getData(ADMIN_TYPE_SIGNALEMENT_URL);
          req = sign?.map((item) => {
            return {
              code: item?.code,
              created_at: formatDate(item?.created_at),
              date: item?.date,
              type: getArrayItemsById(item?.type_signalement?.id, typeSignAll?.data)?.libelle,
              id: item?.id,
              user: formatUserName(item),
              localites: item?.localites,
              workflow: <KjosChip label={label(item?.statut)} color={color(item?.statut)} size="small" variant="contained" />,
              // classe: (
              //   <Circuit
              //     classe={
              //       getArrayItemsById(item?.type_signalement?.id, typeSignAll?.data)?.classe_signalement
              //     }
              //     habiletes={habil}
              //   />
              // ),
            };
          });
        } else {
          console.log("authorizeddddddddddddddddddddddddd1111", query, page, per_page);

          sign = query ? (await getData(ADMIN_SIGNALLEMENT_URL + "?page=" + page + "&per_page=" + per_page + "&" + query))?.data : (await getData(ADMIN_SIGNALLEMENT_URL + "?page=" + page + "&per_page=" + per_page))?.data;
          dispatch(setCount(sign?.total));
          console.log("signnnnnnnnnnnnnnn", sign);

          const habil = (await getData(ADMIN_HABILETE_URL))?.data;
          const typeSignAll = await getData(ADMIN_TYPE_SIGNALEMENT_URL);
          req = sign?.data?.map((item) => {
            return {
              code: item?.code,
              date: item?.date,
              created_at: formatDate(item?.created_at),
              type: getArrayItemsById(item?.type_signalement?.id, typeSignAll?.data)?.libelle,
              id: item?.id,
              user: formatUserName(item),
              localites: item?.localites,
              workflow: <KjosChip label={label(item?.statut)} color={color(item?.statut)} size="small" variant="contained" />,
              // classe: (
              //   <Circuit
              //     classe={
              //       getArrayItemsById(item?.type_signalement?.id, typeSignAll?.data)?.classe_signalement
              //     }
              //     habiletes={habil}
              //   />
              // ),
            };
          });
        }
      } else {
        return {
          error: true,
          message: "Vous n'avez pas les autorisations",
        }
      }

      // on retourn le résultat

      return req;
    },
    [color, dispatch, getData, getUser, isSuperAdmin, label]
  );

  const querySignalement = useCallback(
    async (code) => {
      // on recupère l'utilisateur
      const req = await getData(ADMIN_SIGNALLEMENT_QUERY_URL + "?code=" + code);
      let data = [];
      if (!req?.error) {
        const sign = req?.data?.data && req?.data?.data[0];
        if (sign === undefined || (sign === null && sign === "")) {
          return [];
        } else if (Object.keys(sign).length === 0) {
          return [];
        } else {
          data = {
            code: sign?.code,
            date: sign?.date,
            type: sign?.type_signalement?.libelle,
            id: sign?.id,
            user: sign?.user ? sign?.user?.nom + " " + sign?.user?.prenom : "Anonyme",
            localites: sign?.localites,
            workflow: <KjosChip label={label(sign?.statut ?? "")} color={color(sign?.statut ?? "")} size="small" variant="contained" />,
          };
        }
      }

      // on retourn le résultat

      return [data];
    },
    [color, dispatch, getData, getUser, isSuperAdmin, label]
  );

  const transfererSignalement = useCallback(
    async (signalementId, index_traitement_url = ADMIN_INDEX_TRAITEMENT) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;
      var createIndex;
      // On recupere index de traitement
      if (isSuperAdmin) {
        const habilete_position = signalement?.habilete_position;
        const habiletes = signalement?.classe?.habiletes;

        if (Array.isArray(habiletes)) {
          if (habilete_position < habiletes.length - 1) {
            const current_habiletes = habiletes[habilete_position + 1];
            const old_habiletes = habiletes[habilete_position];

            // const current_habiletes = habiletes[habilete_position]; //code de test
            if (Array.isArray(current_habiletes)) {
              await Promise.all(
                current_habiletes.map(async (item) => {
                  const data = {
                    habilete_id: item,
                    signalement_id: signalementId,
                  };
                  createIndex = await postData(data, index_traitement_url);

                  const data2 = {
                    habilete_id: item,
                    signalement_id: signalementId,
                    action: "create",
                  };
                  await postData(data2, ADMIN_ADMIN_ACTION_URL);
                })
              );
            } else {
              const data = {
                habilete_id: current_habiletes,
                signalement_id: signalementId,
              };
              createIndex = await postData(data, index_traitement_url);
            }

            // Mise à jour statut
            await updateSignalement(signalement?.id, {
              statut: "pendding",
              habilete_position: habilete_position + 1,
            });

            // On supprime les anciens index
            if (!createIndex?.error) {
              await Promise.all(
                old_habiletes?.map(async (item) => {
                  const del = await deleteData2(index_traitement_url + "/" + signalementId + "?habilete_id=" + item);
                })
              );
            }

            return {
              error: false,
              message: "Le signalement a été transféré avec succès",
            };
          } else {
            return {
              error: true,
              message: "Vous avez atteint la fin du traitement",
            };
          }
        }
      } else {
        const habilete_id = getUser("admin")?.user.habilete?.id ?? getUser("admin")?.user.habilete_id;

        const index_traitement = await getData(index_traitement_url + "/" + signalementId + "?habilete_id=" + habilete_id);

        const habilete_position = signalement?.habilete_position;
        const habiletes = signalement?.classe?.habiletes;

        // recuperer la classe [[1,2,3], [1,2], [5,7,8]] du traitement suivant
        if (Array.isArray(habiletes)) {
          if (habilete_position < habiletes.length - 1) {
            const current_habiletes = habiletes[habilete_position + 1];
            const old_habiletes = habiletes[habilete_position];

            // const current_habiletes = habiletes[habilete_position]; //code de test
            if (Array.isArray(current_habiletes)) {
              await Promise.all(
                current_habiletes.map(async (item) => {
                  const data = {
                    habilete_id: item,
                    signalement_id: signalementId,
                  };
                  const data2 = {
                    habilete_id: item,
                    signalement_id: signalementId,
                    action: "create",
                  };
                  createIndex = await postData(data, index_traitement_url);
                  await postData(data2, ADMIN_ADMIN_ACTION_URL);
                })
              );
            } else {
              const data = {
                habilete_id: current_habiletes,
                signalement_id: signalementId,
              };
              const data2 = {
                habilete_id: current_habiletes,
                signalement_id: signalementId,
                action: "create",
              };
              createIndex = await postData(data, index_traitement_url);
            }

            // Mise à jour statut
            await updateSignalement(signalement?.id, {
              statut: "pendding",
              habilete_position: habilete_position + 1,
            });

            // On supprime les anciens index
            if (!createIndex?.error) {
              await Promise.all(
                old_habiletes?.map(async (item) => {
                  const del = await deleteData2(index_traitement_url + "/" + signalementId + "?habilete_id=" + item);
                })
              );
            }

            return {
              error: false,
              message: "Le signalement a été transféré avec succès",
            };
          } else {
            return {
              error: true,
              message: "Vous avez atteint la fin du traitement",
            };
          }
        }
      }

      console.log("on rentre pas");
    },
    [deleteData2, getData, getOneData, getUser, isSuperAdmin, postData, updateSignalement]
  );

  const nexGroup = useCallback(
    async (signalementId, habiletes_url = ADMIN_HABILETE_URL) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;
      // on recupère habilete_position du signalement, si habilete_position === longueur classe signalement on ne transfert plus
      const habilete_position = signalement?.habilete_position;
      const habiletes = signalement?.classe?.habiletes;
      const classes = signalement?.classe?.libelle;

      // recuperer la classe [[1,2,3], [1,2], [5,7,8]] du traitement suivant
      if (Array.isArray(habiletes)) {
        if (habilete_position < habiletes.length - 1) {
          const current_habiletes = habiletes[habilete_position + 1]; //code original
          // const current_habiletes = habiletes[habilete_position]; //code de test
          var habiletesLAbel = [];
          if (Array.isArray(current_habiletes)) {
            // On recupere les habiletes
            await Promise.all(
              current_habiletes.map(async (item) => {
                const hab = await getOneData(item, habiletes_url);
                habiletesLAbel.push(hab?.data?.libelle);
              })
            );
          } else {
            const hab = await getOneData(current_habiletes, habiletes_url);
            habiletesLAbel.push(hab);
          }
          return {
            error: false,
            data: { classe: classes, habiletes: habiletesLAbel },
          };
        } else {
          return {
            error: true,
            message: "Vous avez atteint la fin du traitement",
          };
        }
      } else {
        return {
          error: false,
          message: "Aucune erreur",
        };
      }
    },
    [getOneData]
  );

  const sendRepport = useCallback(
    async (signalementId, habiletes_url = ADMIN_HABILETE_URL) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;
      // on recupère habilete_position du signalement, si habilete_position === longueur classe signalement on ne transfert plus
      const habilete_position = signalement?.habilete_position;
      const habiletes = signalement?.classe?.habiletes;
      const classes = signalement?.classe?.libelle;

      // recuperer la classe [[1,2,3], [1,2], [5,7,8]] du traitement suivant
      if (Array.isArray(habiletes)) {
        if (habilete_position === habiletes.length - 1) {
          const current_habiletes = habiletes[habilete_position]; //code original
          // const current_habiletes = habiletes[habilete_position]; //code de test

          var habiletesLAbel = [];
          if (Array.isArray(current_habiletes)) {
            // On recupere les habiletes
            await Promise.all(
              current_habiletes.map(async (item) => {
                const hab = await getOneData(item, habiletes_url);
                habiletesLAbel.push(hab?.data?.libelle);
              })
            );
          } else {
            const hab = await getOneData(current_habiletes, habiletes_url);
            habiletesLAbel.push(hab);
          }

          // Send by email
          await Promise.all(
            habiletesLAbel.map(async (item) => {
              const hab = await getOneData(item, habiletes_url);
              habiletesLAbel.push(hab?.data?.libelle);
            })
          );

          console.log("current_habiletes", habiletesLAbel);
          return;
          return {
            error: false,
            data: { classe: classes, habiletes: habiletesLAbel },
          };
        } else {
          return {
            error: true,
            message: "Vous avez atteint la fin du traitement",
          };
        }
      } else {
        return {
          error: false,
          message: "Aucune erreur",
        };
      }
    },
    [getOneData]
  );

  const getCurrentGroupe = useCallback(
    async (signalementId, habiletes_url = ADMIN_HABILETE_URL) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;
      // on recupère habilete_position du signalement, si habilete_position === longueur classe signalement on ne transfert plus
      const habilete_position = signalement?.habilete_position;
      const habiletes = signalement?.classe?.habiletes;
      const classes = signalement?.classe?.libelle;

      // recuperer la classe [[1,2,3], [1,2], [5,7,8]] du traitement suivant
      if (Array.isArray(habiletes)) {
        const current_habiletes = habiletes[habilete_position]; //code original
        // const current_habiletes = habiletes[habilete_position]; //code de test

        var habiletesLAbel = [];
        if (Array.isArray(current_habiletes)) {
          // On recupere les habiletes
          await Promise.all(
            current_habiletes.map(async (item) => {
              const hab = await getOneData(item, habiletes_url);
              habiletesLAbel.push(hab);
            })
          );
        } else {
          const hab = await getOneData(current_habiletes, habiletes_url);
          habiletesLAbel.push(hab);
        }
        // Formater les données pour recupérer les utilisateurs
        const users = habiletesLAbel?.map(
          (groupe) =>
            Array.isArray(groupe?.data?.admins) &&
            groupe?.data?.admins.map((admin) => ({
              id: admin?.id,
              label: admin?.nom + " " + admin?.prenom,
            }))
        );

        const mergedUsers = users.reduce((result, current) => {
          const adminArray = current || []; // Handle cases where 'admin' property is missing

          return result.concat(adminArray);
        }, []);

        // end users

        // Formater les goupes pour les afficher
        const groupe = habiletesLAbel?.map((groupe, index) => groupe?.data.libelle);

        return {
          error: false,
          data: { groupe: groupe.join(", "), users: mergedUsers },
        };
      }
    },
    [getOneData]
  );

  const isSignalementOwner = useCallback(
    async (signalement_id) => {
      if (isSuperAdmin) return true;

      // Check if is owner
      const habilete_id = getUser("admin")?.user?.habilete?.id ?? getUser("admin")?.user?.habilete_id;

      let owner = await getData(ADMIN_INDEX_TRAITEMENT + "/" + signalement_id + "?habilete_id=" + habilete_id);

      if (Array.isArray(owner?.data) && owner?.data?.length === 0) {
        return false;
      } else {
        return true;
      }
    },
    [getData, getUser, isSuperAdmin]
  );

  const endedSignalement = useCallback(
    async (signalementId) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;
      const habilete_position = signalement?.habilete_position;
      const habiletes = signalement?.classe?.habiletes;
      console.log(habilete_position);
      console.log(habiletes);
      if (Array.isArray(habiletes)) {
        if (habilete_position === habiletes.length - 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    [getOneData]
  );

  const markAsDelivery = useCallback(
    async (signalementId, index_traitement_url = ADMIN_INDEX_TRAITEMENT) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;
      var createIndex;
      // On recupere index de traitement
      const habilete_id = getUser("admin")?.user.habilete?.id ?? getUser("admin")?.user.habilete_id;

      const index_traitement = await getData(index_traitement_url + "/" + signalementId + "?habilete_id=" + habilete_id);

      const habilete_position = signalement?.habilete_position;
      const habiletes = signalement?.classe?.habiletes;

      // recuperer la classe [[1,2,3], [1,2], [5,7,8]] du traitement suivant
      if (Array.isArray(habiletes)) {
        if (habilete_position === habiletes.length - 1) {
          const old_habiletes = habiletes[habilete_position];

          const update = await updateSignalement(signalementId, { statut: "delivery" });

          // On supprime les anciens index
          // if (!update?.error) {
          //  await Promise.all(
          //     old_habiletes?.map(async (item) => {
          //       const del = await deleteData2(
          //         index_traitement_url + "/" + signalementId + "?habilete_id=" + item
          //       );
          //       console.log("del", del);
          //     })
          //   );
          // }

          return {
            error: false,
            message: "succès",
          };
        } else {
          return {
            error: true,
            message: "Erreur inatendue",
          };
        }
      }
      console.log("on rentre pas");
    },
    [deleteData2, getData, getOneData, getUser, updateSignalement]
  );

  const getAllGroup = useCallback(async () => {
    const groupe = await getData(ADMIN_HABILETE_URL);
    const groupeArray = groupe?.data?.map((item) => {
      return {
        id: item.id,
        label: item.libelle,
      };
    });
    return groupeArray;
  }, [getData]);

  const getAllTypes = useCallback(async () => {
    const type = (await getData(ADMIN_TYPE_SIGNALEMENT_URL))?.data;
    const typeArray = type?.map((item) => {
      return {
        id: item.id,
        label: item.libelle,
      };
    });
    return typeArray;
  }, [getData]);

  return {
    createSignalement,
    updateSignalement,
    getAdminSignalement,
    transfererSignalement,
    nexGroup,
    isSignalementOwner,
    endedSignalement,
    markAsDelivery,
    querySignalement,
    sendRepport,
    getAllSignalement,
    getCurrentGroupe,
    getAllGroup,
    getAllTypes,
  };
};

export default useSignalement;
