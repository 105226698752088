import * as React from "react";
import { Box, Container, Stack } from "@mui/material";
import { ADMIN_SIGNALLEMENT_MAPS_URL } from "config/RoutesUrl";

import useRole from "hooks/useRole";
import KjosAuthorized from "components/KjosAuthorized";
import KjosHeader from "components/KjosHeader";
import BellAlertIcon from "@heroicons/react/24/solid/BellAlertIcon";
import MapPinIcon from "@heroicons/react/24/solid/MapPinIcon";
import KjosTitle from "components/KjosTitle";
import { FANCY_BLUE } from "config/color";
import KjosMap from "../../components/KjosMap";
import useHttp from "hooks/useHttp";

const SignalementsMap = () => {
  const [value, setValue] = React.useState("1");
  const isSuperAdmin = useRole("admin", "superadmin");
  const {getData} = useHttp()
  const [mapData, setMapData] = React.useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getData(ADMIN_SIGNALLEMENT_MAPS_URL).then((res) => {
      console.log(res);
      setMapData(res?.data);
    });
  }, []);

  return (
    <KjosAuthorized permission="read_signalement">
      <KjosHeader>Signalement | ANLC - Map</KjosHeader>
      <Box component={"main"} sx={{ flexGrow: 1, py: 3 }}>
        <Container maxWidth={"xl"}>
          <Stack sx={{ px: 5 }}>
            <KjosTitle icon={<MapPinIcon />} title="Cartographie" subtitle="Cartographie des signalements" iconBgColor={FANCY_BLUE} />
          </Stack>
          <br />

            <KjosMap locations={mapData} zoom={4} />

          {/* </Box> */}
        </Container>
      </Box>
    </KjosAuthorized>
  );
};

export default SignalementsMap;
