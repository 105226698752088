import * as React from "react";
import { Box, Tab, Unstable_Grid2 as Grid, Container, Stack } from "@mui/material";

import useRole from "hooks/useRole";
import KjosAuthorized from "components/KjosAuthorized";
import KjosHeader from "components/KjosHeader";
import BellAlertIcon from "@heroicons/react/24/solid/BellAlertIcon";
import KjosTitle from "components/KjosTitle";
import { FANCY_BLUE } from "config/color";
import { Feedback } from "@mui/icons-material";
import AvisLayout from "pages/avis/layout/AvisLayout";

const Avis = () => {
  const [value, setValue] = React.useState("1");
  const isSuperAdmin = useRole("admin", "superadmin");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <KjosAuthorized permission="read_avis">
      <KjosHeader>Signalement | ANLC - Workflow</KjosHeader>
      <Box component={"main"} sx={{ flexGrow: 1, py: 3 }}>
        <Container maxWidth={"xl"}>
          <Stack sx={{ px: 5 }}>
            <KjosTitle icon={<Feedback />} title="Avis" subtitle="Avis des utilisateurs" iconBgColor={FANCY_BLUE} />
          </Stack>
          <br />
       
          <AvisLayout />   

          {/* </Box> */}
        </Container>
      </Box>
    </KjosAuthorized>
  );
};

export default Avis;
