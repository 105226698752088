import { Box, Card, Checkbox, CircularProgress, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from "@mui/material";
import { Scrollbar } from "components/scrollbar";
import { getInitials } from "utils/get-initials";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setItemsSelected } from "store/TableListReducer";
import { Tooltip } from "recharts";
import { FANCY_BLUE } from "config/color";
import KjosSearch from "components/KjosSearch";
const KjosTable = (props) => {
  const { count = 0, items = [], onDeselectAll, onDeselectOne, onPageChange = () => {}, onRowsPerPageChange, onSelectAll, onSelectOne, page = 0, rowsPerPage = 0, selected = [], header = {}, isCheckBox = true, showIndex = true, title = "", onSearch, placeholder, search = true } = props;
  const { loading } = useSelector((state) => state.signalement);

  const selectedSome = selected.length > 0 && selected.length < items.length;
  const selectedAll = items.length > 0 && selected.length === items.length;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setItemsSelected(selected));
  }, [dispatch, selected, page, rowsPerPage, items]);


  return (
    <Card sx={{ position: "relative" }}>
      {/* Loading */}
      {/* {loading && (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            height: "100%",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            width: "100%",
            backgroundColor: "#f1f1f1",
          }}
        >
          <CircularProgress />
        </Box>
      )} */}
      <Scrollbar>
        <Box sx={{ minWidth: 800, boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.1)" }}>
          <Paper sx={{ width: "100%" }}>
            <EnhancedTableToolbar numSelected={selected.length} title={title} onSearch={onSearch} placeholder={placeholder} search={search} />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {isCheckBox && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAll}
                          indeterminate={selectedSome}
                          onChange={(event) => {
                            if (event.target.checked) {
                              onSelectAll?.();
                            } else {
                              onDeselectAll?.();
                            }
                          }}
                        />
                      </TableCell>
                    )}

                    {showIndex && <TableCell> N° </TableCell>}

                    {Object.keys(header).map((key, index) => (
                      <TableCell key={index}>{header[key]}</TableCell>
                    ))}
                    {/* <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Location
                </TableCell>
                <TableCell>
                  Phone
                </TableCell>
                <TableCell>
                  Signed Up
                </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                {items?.length <= 0 && (
                    <TableRow>
                       <TableCell colSpan={Object.keys(header)?.length + 2} align="center">
                        <Typography variant="body2">Aucune données</Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={Object.keys(header)?.length + 2} align="center">
                        <Typography variant="body2">Chargement...</Typography>
                      </TableCell>
                    </TableRow>
                  )
                  :
                  (items.map((customer, index) => {
                    const isSelected = selected.includes(customer.id);
                    // const createdAt = format(customer.createdAt, "dd/MM/yyyy");

                    return (
                      <TableRow hover key={index} selected={isSelected}>
                        {isCheckBox && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isSelected}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  onSelectOne?.(customer.id);
                                } else {
                                  onDeselectOne?.(customer.id);
                                }
                              }}
                            />
                          </TableCell>
                        )}
                        {showIndex && <TableCell> {index + 1 + rowsPerPage * page} </TableCell>}

                        {Object.keys(header).map((key, index) => {
                          return (
                            <TableCell key={index + "body"}>
                              <Stack alignItems="center" direction="row" spacing={2}>
                                <Typography variant="subtitle2">{customer[key]}</Typography>
                              </Stack>
                            </TableCell>
                          );
                        })}

                        {/* <TableCell>
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                      >
                        <Avatar src={customer.avatar}>
                          {getInitials(customer.name)}
                        </Avatar>
                        <Typography variant="subtitle2">
                          {customer.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {customer.email}
                    </TableCell>
                    <TableCell>
                      {customer.address.city}, {customer.address.state}, {customer.address.country}
                    </TableCell>
                    <TableCell>
                      {customer.phone}
                    </TableCell>
                    <TableCell>
                      {createdAt}
                    </TableCell> */}
                      </TableRow>
                    );
                  }))}

                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Scrollbar>
      <TablePagination component="div" count={count} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} page={page} rowsPerPage={rowsPerPage} rowsPerPageOptions={[5, 10, 25]} />
    </Card>
  );
};

function EnhancedTableToolbar(props) {
  const { numSelected, title, onSearch, placeholder, search=true } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        // }),
        backgroundColor: FANCY_BLUE,
      }}
    >
      <Stack direction="row" justifyContent={"space-between"} sx={{ width: "100%" }} alignItems={"center"}>
      {numSelected > 0 ? (
        <Typography sx={{ flex: "1 1 100%", color: "white" }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: "1 1 100%", color: "white" }} variant="body2" id="tableTitle" component="div">
          {title}
        </Typography>
      )}

      {search && <KjosSearch onSearch={onSearch} placeholder={placeholder} />}
  </Stack>
      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

export default KjosTable;
