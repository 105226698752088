import { useCallback } from "react";
import useHttp from "hooks/useHttp";
import useUser from "./useUser";
import { ADMIN_INDEX_TRAITEMENT, ADMIN_HABILETE_URL, ADMIN_TYPE_SIGNALEMENT_URL, ADMIN_AVIS_URL, ADMIN_HISTORIQUE_URL, ADMIN_ADMIN_ACTION_URL, ADMIN_SIGNALLEMENT_QUERY_URL } from "config/RoutesUrl";
import getArrayItemsById from "controller/getArrayItemsById";
import useRole from "./useRole";
import { Chip } from "@mui/material";
import useStatut from "./useStatut";
import { useDispatch } from "react-redux";
import { setCount } from "store/SignalementReducer";
import KjosChip from "components/KjosChip";
import { FANCY_ORANGE } from "config/color";
import formatDate from "controller/formatDate";
import ReviewComponent from "components/ReviewComponent";
import usePermission from "./usePermission";
import { gridDateFormatter } from "@mui/x-data-grid";

const useAvis = () => {
  const { getData, postData, putData, deleteData, getOneData, deleteData2 } = useHttp(); // get data from server
  const { getUser } = useUser();
  const isSuperAdmin = useRole("admin", "superadmin");
  const { color, label } = useStatut();
  const dispatch = useDispatch();
  const { hasPermission } = usePermission();

  const getAvis = useCallback(
    async (page = 1, per_page = 5, query = null) => {
      let req;
      // permission
      const authorized = (await hasPermission("read_avis"))?.authorized;
      if(authorized){
        const avis = query ? (await getData(ADMIN_AVIS_URL + "?page=" + page + "&per_page=" + per_page + "&" + query)) : (await getData(ADMIN_AVIS_URL + "?page=" + page + "&per_page=" + per_page));
        dispatch(setCount(avis?.count));

         req = avis?.data?.map((item) => {
          return {
            // id: item?.id,
            utilisateur: item?.user?.nom + " " + item?.user?.prenom,
            commentaire: <div style={{ whiteSpace: "pre-wrap", width: "500px" }}> {item?.comment?.slice(0, 500)}</div>,
            note: <ReviewComponent value={item?.note} max={5} />,
            version_app: item?.version_app,
            date_ajout : formatDate(item?.created_at),
          };
        });

      }else{
        return {
          error: true,
          message: "Vous n'avez pas les autorisations pour effectuer cette operation",
        };
  
      }
      return req
    },
    [getData]
  );

     const getAvisForXlsx  = useCallback(
    async (page = 1, per_page = 5, query = null) => {
      let req;
      // permission
      const authorized = (await hasPermission("read_avis"))?.authorized;
      if(authorized){
        const avis = query ? (await getData(ADMIN_AVIS_URL + "?page=" + page + "&per_page=" + per_page + "&" + query)) : (await getData(ADMIN_AVIS_URL + "?page=" + page + "&per_page=" + per_page));
        dispatch(setCount(avis?.count));

         req = avis?.data?.map((item) => {
          return {
            // id: item?.id,
            utilisateur: item?.user?.nom + " " + item?.user?.prenom,
            commentaire: item?.comment,
            note: item?.note,
            version_app: item?.version_app,
            date_ajout : formatDate(item?.created_at),
          };
        });

      }else{
        return {
          error: true,
          message: "Vous n'avez pas les autorisations pour effectuer cette operation",
        };
  
      }
      return req
    },
    [getData]
  );

  
  
  return {
    getAvis,
    getAvisForXlsx,
  };
};

export default useAvis;
