import KjosHeader from "components/KjosHeader";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Container, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { createEmotionCache } from "utils/create-emotion-cache";
import { createTheme } from "theme";
import "simplebar-react/dist/simplebar.min.css";
import { Provider } from "react-redux";
import { store } from "store/store";

function insertMetaTag(name, content) {
  const meta = document.createElement("meta");
  meta.setAttribute("name", name);
  meta.setAttribute("content", content);
  document.head.appendChild(meta);
}

const clientSideEmotionCache = createEmotionCache();

const SplashScreen = () => null;

const App = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, children } = props;
  insertMetaTag("viewport", "initial-scale=1, width=device-width");

  const theme = createTheme();

  return (
    <CacheProvider value={emotionCache}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth={false} disableGutters>
            {children}
            </Container>
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    </CacheProvider>
  );
};

export default App;
