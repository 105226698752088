/* eslint-disable react/jsx-max-props-per-line */
import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpen } from 'store/AuthenticationReducer';
import { Stack } from '@mui/system';
import { FANCY_GREEN } from 'config/color';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function KjosDialog2(props) {
   var {title, body, button, submitCallback, fullScreen, dialogMessage, dialogMessageColor, open, handleClose, spiner} = props
  const handleSave = (e) => {
   submitCallback(e)
  }


  useEffect(()=>{ 
   return () => {
      setOpen(false)
   }
  },[])

  return (
    <div>
      <BootstrapDialog
      fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%" } } }}
      >
        <DialogTitle sx={{ mr:4 , p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
         {body}
        </DialogContent>
        
        <DialogActions>
          <Stack spacing={2} direction="row" alignItems={"center"}>
          {spiner}
          <Typography variant="body2" sx={{ color: dialogMessageColor }} >
           {dialogMessage}
          </Typography>
          <Button autoFocus onClick={handleSave} sx={{ color: FANCY_GREEN }} variant='contained'>
            {button}
          </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}